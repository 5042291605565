








































































































































































































import Vue from 'vue';
import { mapActions, mapGetters, mapMutations  } from 'vuex';
import ElementViewDialog from '@/components/ElementViewDialog.vue';
import ElementAddEditDialog from '@/components/ElementAddEditDialog.vue';
import ElementTableFilter from '@/components/ElementTableFilter.vue';
import TableItemFilterMenu from '@/components/TableItemFilterMenu.vue';

import {
  mdiInformation,
  mdiMagnify,
  mdiRotate3d,
  mdiDotsVertical,
  mdiPencil,
  mdiDelete,
  mdiPlus,
  mdiAtomVariant,
  mdiFilterMenu,
  mdiFilterOutline,
  mdiFilterCheck,
  mdiFilterVariant,
  mdiSortAscending,
  mdiSortDescending
} from '@mdi/js';

import {
  SET_PROJECT_TAB_INDEX,
  ProjectTab,
  DELETE_ELEMENT,
  GET_CURRENT_PROJECT,
  GET_ELEMENT_SELECTED,
  ElementType,
  Element,
  ProjectFile,
  GET_INVENTORY_FILTER_ACTIVE,
  ElementTypeFileDownloadDelete,
  GET_ELEMENTS_FILTERED,
  ElementDelete,
  SET_ELEMENTS_SELECTION,
  GET_ELEMENTS_SELECTION,
  FETCH_ELEMENT_SELECTED,
  SET_ELEMENT_SELECTED,
  FETCH_ELEMENT_IMAGE_TYPE_SELECTED,
  SET_CIRCULARITY_SELECTED,
  SET_ELEMENTS_FILTER,
  FilterQuery,
  FilterQuerySelect,
  FilterQueryCondition,
  ReuseDecision,
  SurfaceDamage,
  HazardAssessment,
  HazardAssessmentStatus,
  FETCH_ELEMENTS_FILTERED,
  GET_ELEMENTS_FILTER
} from '@/store/project';

import { SET_IFCIDS, GET_VIEWER_INITIALISED } from '@/store/viewer';

export default Vue.extend({
  name:"ElementTable",
  components: {
    ElementTableFilter,
    ElementViewDialog,
    ElementAddEditDialog,
    TableItemFilterMenu
  },
  data() {

    const reuseDecisionList = Object.values(ReuseDecision).map(decision => ({ value: decision, text: `${decision}` }));
    const surfaceDamageList = Object.values(SurfaceDamage).map(damage => ({ value: damage, text: `${damage}` }));
    const hazardAssessmentList = Object.values(HazardAssessment).map(assessment => ({ value: assessment, text: `${assessment}` }));
    const hazardAssessmentStatusList = Object.values(HazardAssessmentStatus).map(status => ({ value: status, text: `${status}` }));

    return {
      mdiInformation,
      mdiMagnify,
      mdiRotate3d,
      mdiDotsVertical,
      mdiPencil,
      mdiDelete,
      mdiPlus,
      mdiAtomVariant,
      mdiFilterMenu,
      mdiFilterOutline,
      mdiFilterCheck,
      mdiFilterVariant,
      mdiSortAscending,
      mdiSortDescending,
      reuseDecisionList,
      surfaceDamageList,
      hazardAssessmentList,
      hazardAssessmentStatusList,
      search: '',
      isLoading: true,
      offset: 0,
      size: 10,
      options: {
        groupBy: [],
        groupDesc: [],
        itemsPerPage: 10,
        multiSort: false,
        mustSort: false,
        page: 1,
        sortBy: [],
      },
      oldOptions: {
        groupBy: [],
        groupDesc: [],
        itemsPerPage: 10,
        multiSort: false,
        mustSort: false,
        page: 1,
        sortBy: [],
      },
      // elements properties to display
      headers: [
        { text: 'Name', value: 'name', sortable: false },
        { text: 'Description', value: 'description', sortable: false, width: "10%"  },
        { text: 'Surface Damage', value: 'surfaceDamage', sortable: false, width: "5%"  },
        { text: 'Reuse Potential', value: 'reusePotential', sortable: false, width: "5%"  },
        { text: 'Reuse Decision', value: 'reuseDecision', sortable: false, width: "5%"  },
        { text: 'Hazard', value: 'hazardAssessment', sortable: false, width: "5%"  },
        { text: 'Assessment Status', value: 'hazardAssessmentStatus', sortable: false, width: "5%"  },
        { text: 'Info', value: 'info', align: 'center', sortable: false, width: "5%" },
        { text: '3D', value: 'view-3d', align: 'center', sortable: false, width: "5%" },
        { text: 'Actions', value: 'actions', align: 'center', sortable: false, width: "5%" },
      ],
      showAddInventoryElementDialog: false,
      editingElement: null,
      confirmDeletion: false,
      elementInfoEnabled: false,
      elementAddEditDialogEnabled: false,
      elementAddEditDialogEditMode: false,
      dynamicBimEnabled: false,
      filterDialogEnabled: false
    }
  },
  created() {
    this.setElementsFilter({
      size: 10,
      offset: 0,
      selects: [] as FilterQuerySelect[],
      conditions: [] as FilterQueryCondition[]
    } as FilterQuery)
  },
  methods: {
    ...mapActions({
      fetchElementWithDependencies: FETCH_ELEMENT_SELECTED,
      deleteElement: DELETE_ELEMENT,
      fetchElementsFiltered: FETCH_ELEMENTS_FILTERED
    }),
    ...mapMutations({
      setProjectTabIndex: SET_PROJECT_TAB_INDEX,
      setElementWithDependencies: SET_ELEMENT_SELECTED,
      setCircularitySelected: SET_CIRCULARITY_SELECTED,
      setIfcIds: SET_IFCIDS,
      setElementsFilter: SET_ELEMENTS_FILTER
    }),
    createElement() {
      this.setElementWithDependencies({} as Element);
      this.setCircularitySelected(undefined);
      this.elementAddEditDialogEnabled = true;
      this.elementAddEditDialogEditMode = false;
    },
    editElement(item: Element): void {
      if (item.projectId === undefined) item.projectId = this.currentProject.id;
        this.fetchElementWithDependencies(item);
        this.elementAddEditDialogEnabled = true;
        this.elementAddEditDialogEditMode = true;
    },
    focusElementOnBim(item: Element): void {
      if (this.isViewerInitialised == false) return;
      this.setIfcIds([item.ifcId]);
      this.setProjectTabIndex(ProjectTab.Model);
    },
    async onClickRemove(payload: ElementDelete) {
      if (!this.confirmDeletion) {
        this.confirmDeletion = true;
        return;
      }
      await this.deleteElement(payload);
      this.confirmDeletion = false;
    },
    toggleElementInfo(item: Element): void {
      if(this.elementInfoEnabled === false) {
        if (item.projectId === undefined) item.projectId = this.currentProject.id;
        this.$store.dispatch(FETCH_ELEMENT_SELECTED, item);
      }
      this.elementInfoEnabled = !this.elementInfoEnabled;
    },
    toggleDynamicBIM(){
      this.dynamicBimEnabled = !this.dynamicBimEnabled;
    },
    rollBackfilter () {
      this.options.itemsPerPage = this.oldOptions.itemsPerPage;
      this.updateFilterParams(this.oldOptions);
    },
    async updateFilterParams(newValue: any) {
      if (this.filterDialogEnabled) this.filterDialogEnabled = false;
      this.isLoading = true;
      if (newValue.itemsPerPage === -1) newValue.itemsPerPage = this.elementsFiltered.count;
      try {
        //let sizeLimit = newValue.itemsPerPage === -1 ? 30 : newValue.itemsPerPage;
        this.elementsFilter.size = newValue.itemsPerPage;
        this.elementsFilter.offset = (newValue.page - 1) * newValue.itemsPerPage;
        await this.fetchElementsFiltered();
      } catch(error) {
      } finally {
        this.isLoading = false;
      }
    },
  },
  computed: {
    ...mapGetters({
      elementsFiltered: GET_ELEMENTS_FILTERED,
      currentProject: GET_CURRENT_PROJECT,
      elementWithDependencies: GET_ELEMENT_SELECTED,
      isViewerInitialised: GET_VIEWER_INITIALISED,
      inventoryFilterActive: GET_INVENTORY_FILTER_ACTIVE, // TO DO - adapt
      elementsFilter: GET_ELEMENTS_FILTER
    }),
    checkElementType(): ElementType {
      if (this.elementWithDependencies.elementType) return this.elementWithDependencies.elementType;
      else return {} as ElementType;
    },
    selectedInventoryElements: {
      get(): Element[] {
        return this.$store.getters[GET_ELEMENTS_SELECTION];
      },
      set(selectedItems: Element[]) {
        this.$store.commit(SET_ELEMENTS_SELECTION, selectedItems);
        if (!this.isViewerInitialised) return;
        if (this.dynamicBimEnabled == true) {
          let ifcIds = selectedItems ? selectedItems.map(v => v.ifcId) : [];
          this.setIfcIds(ifcIds);
        }
      },
    },
  },
  watch: {
    elementsFiltered: {
      deep: true,
      immediate: true,
      handler: function(newValue, oldValue) {
        if (!this.isViewerInitialised) return;
        if (this.dynamicBimEnabled == true) {
          let ifcIds = newValue.data ? newValue.data.map((v: { ifcId: any; }) => v.ifcId) : [];
          this.setIfcIds(ifcIds);
        }
      }
    },
    elementWithDependencies(newElement, oldElement)
    {
      let elementType = newElement.elementType;
      if (elementType === undefined || elementType == null) return;
      else {
        if (elementType.files === undefined || elementType.files == null) return;
        
        elementType.files.forEach((document: ProjectFile) => {
        if (document.fileType === "image/png" || document.fileType === "image/jpeg" || document.fileType === "image/webp")
        {
          this.$store.dispatch(FETCH_ELEMENT_IMAGE_TYPE_SELECTED, { 
            projectId:this.currentProject.id, 
            elementTypeUid: elementType.uid, 
            document: document
          } as ElementTypeFileDownloadDelete);
        }
        });
      }
    },
    options: {
      deep: true,
      immediate: true,
      handler(newValue, oldValue) {
        if (newValue.itemsPerPage === -1 && this.elementsFiltered.count > 500) {
          this.filterDialogEnabled = true;
          this.oldOptions = { ...oldValue };
        } else {
          this.updateFilterParams(newValue);
        }
      },
    },
  },
});
