var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-card',{attrs:{"outlined":"","min-height":"600px"}},[_c('v-row',{staticClass:"mx-5 my-5",attrs:{"align":"center"}},[_c('b',[_vm._v("Location ")]),(_vm.locationBreadcrumbs.length > 0)?_c('v-breadcrumbs',{attrs:{"density":"compact","items":_vm.locationBreadcrumbs,"divider":" - "}}):_c('v-breadcrumbs',{attrs:{"density":"compact","items":_vm.noneLocationBreadCrumb,"divider":" - "}}),_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","disabled":!_vm.canUploadProjectDocument()},on:{"click":function($event){_vm.displayUpsertDocumentDialog = true}}},[_c('v-icon',[_vm._v(_vm._s(_vm.icons.mdiPlus))])],1)],1),_c('v-row',{staticClass:"mx-3"},[_c('v-col',{attrs:{"cols":"3"}},[_c('v-treeview',{attrs:{"items":_vm.formatedProjectLocations,"item-children":"subdivisions","item-key":"id","hoverable":"","rounded":"","transition":""},scopedSlots:_vm._u([{key:"prepend",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{attrs:{"color":"primary"}},[_vm._v(" "+_vm._s(_vm.icons[item.type])+" ")])]}},{key:"label",fn:function(ref){
var item = ref.item;
return [_c('div',{staticStyle:{"cursor":"pointer"},on:{"click":function($event){$event.stopPropagation();return _vm.selectLocation(item)}}},[_vm._v(" "+_vm._s(item.name)+" "+_vm._s(_vm.filesNumberForLocation(item))+" ")])]}}])})],1),_c('v-col',{attrs:{"cols":"9"}},[_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',[_vm._v("Documents at current location")])],1),(_vm.isEmpty(_vm.selectedLocationFiles))?_c('span',[_vm._v(" no documents here ")]):_c('v-data-table',{attrs:{"headers":_vm.documentsHeaders,"items":_vm.selectedLocationFiles,"calculate-widths":"","sort-by":"uploadedAt","dense":"","value":[]},scopedSlots:_vm._u([{key:"item.title",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"pre-wrapped elipsed"},'div',attrs,false),on),[_vm._v(" "+_vm._s(item.title)+" ")])]}}],null,true)},[_c('span',{staticClass:"pre-wrapped"},[_vm._v(" "+_vm._s(item.title)+" ")])])]}},{key:"item.description",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"pre-wrapped elipsed"},'div',attrs,false),on),[_vm._v(" "+_vm._s(item.description)+" ")])]}}],null,true)},[_c('span',{staticClass:"pre-wrapped"},[_vm._v(" "+_vm._s(item.description)+" ")])])]}},{key:"item.documentDate",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getFormatedDocumentDate(item.documentDate))+" ")]}},{key:"item.uploadedAt",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getFormatedDocumentDate(item.uploadedAt))+" ")]}},{key:"item.size",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.formatBytes(item.size)))])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-menu',{attrs:{"position-x":_vm.x,"position-y":_vm.y,"absolute":"","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(item.type !== 'project')?_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","color":"primary"},on:{"click":function($event){return _vm.showContextMenu($event, item)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" "+_vm._s(_vm.mdiDotsVertical)+" ")])],1):_vm._e()]}}],null,true),model:{value:(_vm.contextMenuDisplayed),callback:function ($$v) {_vm.contextMenuDisplayed=$$v},expression:"contextMenuDisplayed"}},[_c('DocumentOptionMenu',{attrs:{"displayUpsertDocumentDialog":function () { return (_vm.displayUpsertDocumentDialog = true); },"downloadProjectDocument":_vm.downloadProjectDocument,"displayDeleteDocumentDialog":function () { return (_vm.displayDeleteDocumentDialog = true); },"canUpdateProjectDocument":_vm.canUpdateProjectDocument,"canDownloadProjectDocument":_vm.canDownloadProjectDocument,"canDeleteProjectDocument":_vm.canDeleteProjectDocument}})],1)]}}],null,true)})],1)],1),_c('v-dialog',{attrs:{"max-width":"40%"},model:{value:(_vm.displayUpsertDocumentDialog),callback:function ($$v) {_vm.displayUpsertDocumentDialog=$$v},expression:"displayUpsertDocumentDialog"}},[_c('DocumentAddEditCard',{attrs:{"currentLocation":_vm.selectedLocation,"siteAndLocation":_vm.locationBreadcrumbs,"closeUpsertDocumentForm":_vm.closeUpsertDocumentForm,"currentDocument":_vm.selectedDocument}})],1),_c('v-dialog',{attrs:{"max-width":"25%"},model:{value:(_vm.displayDeleteDocumentDialog),callback:function ($$v) {_vm.displayDeleteDocumentDialog=$$v},expression:"displayDeleteDocumentDialog"}},[_c('DocumentDeleteCard',{attrs:{"locations":_vm.locationBreadcrumbs,"currentDocument":_vm.selectedDocument,"hideDeleteDocumentDialog":_vm.hideDeleteDocumentDialog}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }