









































import Vue from 'vue';

export default Vue.extend({
  name: 'ElementViewImageCarousel',
  data: () => ({
  }),
  methods: {
    getSampleImage(index: number) {
      return this.placeHolderImages[index];
    }
  },
  props: {
    elementImages: {
      required:false,
      default: []
    },
    typeImages: {
      required:false,
      default: []
    },
    placeHolderImages: {
      required:false,
      default: []
    },
    height: {
        required: false,
        default: 500
    }
  }
});
