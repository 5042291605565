




































































import Vue from 'vue';

import { mdiChevronDoubleDown, mdiChevronDoubleUp} from '@mdi/js';

import { ClassificationEntry, ElementType } from '@/store/project';

export default Vue.extend({
  name: 'ElementViewMetadataCard',
  data: () => ({
    // icons
    mdiChevronDoubleDown,
    mdiChevronDoubleUp,
    classificationExpandButtonEnabled: true,
    classificationTabelHeaders: [
      {
        text: 'Label',
        value: 'label',
        sortable: false,
        width: "40%"
      },
      {
        text: 'Code',
        value: 'code',
        sortable: false,
        width: "30%"
      }
    ],
  }),
  props: {
    element: {
        required:true,
        type: Object as () => Element,
        default(){ return {} as Element }
    },
    elementType: {
        required:true,
        type: Object as () => ElementType,
        default(){ return {} as ElementType }
    },
    classificationEntries: {
        required:true,
        type: Array as () => ClassificationEntry[],
        default(){ return [] as ClassificationEntry[] }
    },
    height: {
        required: false,
        default: 520
    }
  }
});
