


































































import Vue from 'vue';
import {  mdiChevronDoubleUp, mdiHammer, mdiTreeOutline, mdiHazardLights, mdiStateMachine, mdiTriangleOutline, mdiMaterialDesign, mdiGold } from '@mdi/js';


export default Vue.extend({
  name: 'ElementViewReuseTable',
  data: () => ({
    // icons
    mdiChevronDoubleUp,
    mdiHammer,
    mdiTreeOutline,
    mdiHazardLights,
    mdiStateMachine,
    mdiTriangleOutline,
    mdiMaterialDesign,
    mdiGold,

  }),
  methods: {
    colorChip(attribute: any) {
      // if null or undefined
      if (attribute) {
        // reusePotential
        if (attribute > 0.7) return 'green';
        else if (attribute > 0.4) return 'orange';
        else if (attribute < 0.4) return 'red';
        // reuseDecision
        else if (attribute === "reuse") return 'green';
        else if (attribute === "recycling") return 'orange';
        else if (attribute === "backfilling") return 'red';
        // surfaceDamage
        else if (attribute === "high") return 'red';
        else if (attribute === "medium") return 'orange';
        else if (attribute === "low" ) return 'yellow';
        else if (attribute === "none") return 'green';
        // hazardAsessment
        else if (attribute === "requested") return 'red';
        else if (attribute === "in_progress") return 'yellow';
        else if (attribute === "finished" ) return 'green';
        // hazard
        else if (attribute === "no_hazard") return 'green';
        else if (attribute === "connection_only") return 'yellow';
        else if (attribute === "surface_only" ) return 'orange';
        else if (attribute === "overall" ) return 'red';
      }
      else return 'gray';
    },
  },
  props: {
    element: {
        required:true,
        default: {}
    },
    height: {
        required: false,
        default: 520
    }
  }
});
