




































































































































































































































import Vue from 'vue';
import { mapActions, mapGetters } from 'vuex';
import { mdiChevronDoubleDown, mdiChevronDoubleUp, mdiImageArea, mdiDelete } from '@mdi/js';
import {
  CREATE_ELEMENT_TYPE,
  UPDATE_ELEMENT_TYPE,
  GET_CURRENT_PROJECT,
  UPLOAD_ELEMENT_TYPE_IMAGE,
  DELETE_ELEMENT_TYPE_IMAGE,
  ElementType,
  ClassificationEntry,
  ProjectFile,
  ElementTypeFileUpload,
  ElementTypeFileDownloadDelete,
  GET_CIRCULARITY_SELECTED
} from '@/store/project';

import ElementTypeAddEditClassificationTable from '@/components/ElementTypeAddEditClassificationTable.vue';
import CircularityAddEditCard from '@/components/CircularityAddEditCard.vue';

export default Vue.extend({
  name: 'ElementTypeAddEditDialog',
  components: { ElementTypeAddEditClassificationTable, CircularityAddEditCard },
  props:{
    elementType: {
      required:true,
      type: Object as () => ElementType,
      default(){ return {} as ElementType }
    },
    dialogEnabled: {
      required: true,
      default: false
    },
    editMode: {
      required: true,
      default: false
    }
  },
  data: () => ({
    //icons 
    mdiChevronDoubleDown,
    mdiChevronDoubleUp,
    mdiImageArea,
    mdiDelete,
    // others
    ifcExpandEnabled: false,
    tradePropertiesExpandEnabled: false,
    codesEnabledExpanded: false,
    valid: false,
    editModeCircularity: false,
    // selected image for upload
    imageToUpload: {} as File,
    step: 1,
  }),
  computed: {
    ...mapGetters({
      currentProject: GET_CURRENT_PROJECT,
      circularitySelected: GET_CIRCULARITY_SELECTED
    }),
  },

  methods: {
    ...mapActions({
      createElementType: CREATE_ELEMENT_TYPE,
      updateElementType: UPDATE_ELEMENT_TYPE,
      uploadElementTypeImage: UPLOAD_ELEMENT_TYPE_IMAGE,
      deleteElementTypeImage: DELETE_ELEMENT_TYPE_IMAGE
    }),
    setClassifications(classifications: ClassificationEntry[]) {
      this.elementType.classificationEntries = classifications;
      this.codesEnabledExpanded = false;
    },
    close() {
      this.$emit('addEditElementTypeDialogClosed');
    },
    submit() {
      if (this.editMode) this.modify();
      else this.create();
    },
    closeDialog() {
      this.$emit('addEditElementTypeDialogClosed');
      this.step = 1;
    },
    async create() {
      let newElementType = await this.createElementType({
        ...this.elementType,
        projectId: this.currentProject.id,
      });

      // copy received UID and move to step 2
      if(newElementType) {
        this.elementType.uid = newElementType.uid;
        this.step = 2;
      }
      else this.closeDialog();
    },
    async modify() {
      setTimeout(async () => {
        // TO DO - update files
        delete this.elementType.files;
        let isOkay = await this.updateElementType(this.elementType);
        if(isOkay) this.step = 2;
        else this.closeDialog();
      });
    },
    onChangeImage(file: File) {
      if (!!file) {
        this.imageToUpload = file;
      }
    },
    async uploadFile() {
      await this.uploadElementTypeImage({
        projectId: this.currentProject.id,
        elementTypeUid: this.elementType.uid,
        file: this.imageToUpload,
        title: this.elementType.name + "-" + Date.now().toString(),
        description: undefined,
        documentAuthor: undefined,
        documentDate: undefined,
      } as ElementTypeFileUpload);
    },
    async deleteFile(document: ProjectFile) {
      await this.deleteElementTypeImage({
        projectId: this.currentProject.id,
        elementTypeUid: this.elementType.uid,
        document: document
      } as ElementTypeFileDownloadDelete)
    }
  },
  watch: {
    circularitySelected(newValue, oldValue) {
      if (newValue) this.editModeCircularity = true;
      else this.editModeCircularity = false;
    },
    elementType: {
      deep: true,
      handler(newValue, oldValue) {
        for (const key in newValue) {
          if (typeof newValue[key] === 'string' && newValue[key] === '')
            (this.elementType as any)[key] = null;
        }
        if (this.editMode === false) this.step = 1;
      },
    },
  },
});

