












































import Vue from 'vue'
export default Vue.extend({
    name: "DocumentOptionMenu",
    props:{
        displayUpsertDocumentDialog: {
            type: Function,
            required: true
        },
        downloadProjectDocument: {
            type: Function,
            required: true
        },
        displayDeleteDocumentDialog: {
            type: Function,
            required: true
        },
        canUpdateProjectDocument: {
            type: Function,
            required: true
        },
        canDownloadProjectDocument: {
            type: Function,
            required: true
        },
        canDeleteProjectDocument: {
            type: Function,
            required: true
        }
    }
})
