


















































































import Vue from 'vue';
import { mapActions, mapGetters, mapMutations } from 'vuex';
import { mdiDotsVertical, mdiPencil, mdiDelete, mdiPlus } from '@mdi/js';

import MaterialTypeAddEditDialog from '@/components/MaterialTypeAddEditDialog.vue';
import ElementTableFilter from '@/components/ElementTableFilter.vue';

import {
  GET_CURRENT_PROJECT,
  GET_MATERIAL_TYPES,
  FETCH_MATERIAL_TYPES,
  DELETE_MATERIAL_TYPE,
  GET_MATERIAL_TYPE_SELECTED,
  FETCH_MATERIAL_TYPE_SELECTED,
  SET_MATERIAL_TYPE_SELECTED,
  SET_CIRCULARITY_SELECTED
} from '@/store/project';

import { MaterialType, MaterialTypeAnalysis } from '@/store/project/types';

export default Vue.extend({
  name: 'MaterialTypeTable',
  components: { MaterialTypeAddEditDialog, ElementTableFilter },
  data() {
    const headers = [
      { text: 'Name', value: 'name' },
      { text: 'Description', value: 'description', width: "25%" },
      { text: 'Category', value: 'category', width: "10%" },
      { text: 'Hazard', value: 'isHazard', width: "10%" },
      { text: 'Total mass', value: 'totalMass', width: "10%" },
      { text: 'Total volume', value: 'totalVolume', width: "10%" },
      { text: 'Count', value: 'count', align: 'right', width: "5%" },
      { text: 'Actions', value: 'action', sortable: false, width: "5%" },
    ];
    return {
      mdiDotsVertical,
      mdiPencil,
      mdiDelete,
      mdiPlus,
      materialTypeAddEditDialogEnabled: false,
      materialTypeAddEditDialogEditMode: false,
      headers,
      confirmDeletion: false,
      item: {} as MaterialTypeAnalysis,
    };
  },
  computed: {
    ...mapGetters({
      currentProject: GET_CURRENT_PROJECT,
      materialTypes: GET_MATERIAL_TYPES,
      materialTypeSelected: GET_MATERIAL_TYPE_SELECTED
    }),
  },
  methods: {
    ...mapActions({
      fetchMaterialTypes: FETCH_MATERIAL_TYPES,
      fetchMaterialTypeSelected: FETCH_MATERIAL_TYPE_SELECTED,
      deleteMaterialType: DELETE_MATERIAL_TYPE
    }),
    ...mapMutations({
      setMaterialTypeSelected: SET_MATERIAL_TYPE_SELECTED,
      setCircularitySelected: SET_CIRCULARITY_SELECTED
    }),
    createMaterialType() {
      this.setMaterialTypeSelected({} as MaterialType);
      this.setCircularitySelected(undefined);
      this.materialTypeAddEditDialogEditMode = false;
      this.materialTypeAddEditDialogEnabled = true;

    },
    editMaterialType(item: MaterialType) {
      this.fetchMaterialTypeSelected(item);
      this.materialTypeAddEditDialogEditMode = true;
      this.materialTypeAddEditDialogEnabled = true;
    },
    onMenuClick(action: string, item: MaterialTypeAnalysis) {
      if (action === 'edit') {
        this.editMaterialType(item);
        this.confirmDeletion = false;
      } else if (action === 'delete' && !this.confirmDeletion) {
        this.confirmDeletion = true;
      } else if (action === 'delete' && this.confirmDeletion) {
        this.deleteMaterialType(item);
        this.confirmDeletion = false;
      }
    },
    fetchDataFromBackend() {
        // TO DO - when element type filter is implemented
    },
    setFilters() {
        // TO DO - when element type filter is implemented
    }
  },
  async created() {
    await this.fetchMaterialTypes(this.currentProject.id);
  }
});
