var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',[_c('v-data-table',{attrs:{"dense":"","headers":_vm.physicalPropertiesTableHeaders,"items":_vm.properties,"hide-default-footer":""},scopedSlots:_vm._u([{key:"item.propertyType.name",fn:function(ref){
var item = ref.item;
return [(_vm.editProperty.uid === item.uid)?_c('v-autocomplete',{attrs:{"dense":"","items":_vm.propertyTypes,"item-text":function (property) { return property.name; },"item-value":function (property) { return property; },"hide-details":""},model:{value:(_vm.editProperty.propertyType),callback:function ($$v) {_vm.$set(_vm.editProperty, "propertyType", $$v)},expression:"editProperty.propertyType"}}):_c('span',[_vm._v(_vm._s(item.propertyType.name))])]}},{key:"item.propertyUnit.symbol",fn:function(ref){
var item = ref.item;
return [(_vm.editProperty.uid === item.uid)?_c('v-autocomplete',{attrs:{"dense":"","items":_vm.editProperty.propertyType.propertyUnits,"item-text":function (propertyUnit) { return propertyUnit.symbol; },"item-value":function (propertyUnit) { return propertyUnit; },"hide-details":""},model:{value:(_vm.editProperty.propertyUnit),callback:function ($$v) {_vm.$set(_vm.editProperty, "propertyUnit", $$v)},expression:"editProperty.propertyUnit"}}):_c('span',[_vm._v(_vm._s(item.propertyUnit ? item.propertyUnit.symbol : ""))])]}},{key:"item.value",fn:function(ref){
var item = ref.item;
return [(_vm.editProperty.uid === item.uid)?_c('v-text-field',{attrs:{"hide-details":"","dense":"","single-line":"","autofocus":""},model:{value:(_vm.editProperty.value),callback:function ($$v) {_vm.$set(_vm.editProperty, "value", $$v)},expression:"editProperty.value"}}):_c('span',[_vm._v(_vm._s(item.value))])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [(_vm.editProperty.uid === item.uid)?_c('div',[_c('v-icon',{staticClass:"mr-3",attrs:{"color":"red"},on:{"click":function($event){return _vm.resetEntry()}}},[_vm._v(" "+_vm._s(_vm.mdiWindowClose)+" ")]),_c('v-icon',{attrs:{"color":"green"},on:{"click":function($event){return _vm.saveEntry(item)}}},[_vm._v(" "+_vm._s(_vm.mdiContentSave)+" ")])],1):_c('div',[_c('v-icon',{staticClass:"mr-3",attrs:{"color":"green"},on:{"click":function($event){return _vm.editEntry(item)}}},[_vm._v(" "+_vm._s(_vm.mdiPencil)+" ")]),_c('v-icon',{attrs:{"color":"red"},on:{"click":function($event){return _vm.deleteEntry(item)}}},[_vm._v(" "+_vm._s(_vm.mdiDelete)+" ")])],1)]}}],null,true)})],1),_c('br'),_c('v-container',[_c('v-row',[_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.toggleAddPropertyType}},[_vm._v("Create")]),_c('v-spacer'),_c('v-spacer'),_c('v-btn',{attrs:{"color":"secondary"},on:{"click":function($event){return _vm.addNewEntry()}}},[_c('v-icon',[_vm._v(_vm._s(_vm.mdiPlus))])],1),_c('v-spacer'),_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){return _vm.validateProperties(_vm.properties)}}},[_c('v-icon',[_vm._v(_vm._s(_vm.mdiCheck))])],1)],1)],1),_c('PropertyTypeAddEditDialog',{attrs:{"propertyType":_vm.emptyPropertyType,"editMode":false,"dialogEnabled":_vm.addPropertyTypeDialogEnabled},on:{"addEditPropertyTypeDialogClosed":_vm.toggleAddPropertyType,"exit":_vm.toggleAddPropertyType}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }