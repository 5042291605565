var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-table',{staticClass:"elevation-5",attrs:{"headers":_vm.headers,"items":_vm.elementTypesFiltered.data,"items-per-page":10,"loading":_vm.isLoading,"item-per-page":_vm.options.itemsPerPage,"options":_vm.options,"item-key":"uid","show-select":true,"server-items-length":_vm.elementTypesFiltered.count},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-row',{staticClass:"ml-4 mr-4",attrs:{"align":"center"}},[_c('v-col'),_c('v-spacer'),_c('v-col',{attrs:{"align":"center"}},[_c('v-btn',{staticClass:"mb-3",attrs:{"x-large":"","plain":"","d-block":"","text":""}},[_vm._v("Element Types Inventory")])],1),_c('v-spacer'),_c('v-col',{attrs:{"align":"right"}},[_c('v-btn',{staticClass:"mr-2",attrs:{"color":"primary"},on:{"click":_vm.createElementType}},[_c('v-icon',[_vm._v(_vm._s(_vm.mdiPlus))])],1)],1)],1)]},proxy:true},{key:"header.name",fn:function(ref){
var header = ref.header;
return [_c('TableItemFilterMenu',{attrs:{"fetchFunction":_vm.fetchElementTypesFiltered,"parentFilter":_vm.elementTypesFilter,"header":header,"isFilterable":true,"isNumericalFilter":false}})]}},{key:"header.description",fn:function(ref){
var header = ref.header;
return [_c('TableItemFilterMenu',{attrs:{"fetchFunction":_vm.fetchElementTypesFiltered,"parentFilter":_vm.elementTypesFilter,"header":header,"isFilterable":true,"isNumericalFilter":false}})]}},{key:"header.designer",fn:function(ref){
var header = ref.header;
return [_c('TableItemFilterMenu',{attrs:{"fetchFunction":_vm.fetchElementTypesFiltered,"parentFilter":_vm.elementTypesFilter,"header":header,"isFilterable":true,"isNumericalFilter":false}})]}},{key:"header.trademark",fn:function(ref){
var header = ref.header;
return [_c('TableItemFilterMenu',{attrs:{"fetchFunction":_vm.fetchElementTypesFiltered,"parentFilter":_vm.elementTypesFilter,"header":header,"isFilterable":true,"isNumericalFilter":false}})]}},{key:"header.historicalValue",fn:function(ref){
var header = ref.header;
return [_c('TableItemFilterMenu',{attrs:{"fetchFunction":_vm.fetchElementTypesFiltered,"parentFilter":_vm.elementTypesFilter,"header":header,"isFilterable":true,"isNumericalFilter":false}})]}},{key:"header.category",fn:function(ref){
var header = ref.header;
return [_c('TableItemFilterMenu',{attrs:{"fetchFunction":_vm.fetchElementTypesFiltered,"parentFilter":_vm.elementTypesFilter,"header":header,"isFilterable":true,"isNumericalFilter":false}})]}},{key:"header.ifcType",fn:function(ref){
var header = ref.header;
return [_c('TableItemFilterMenu',{attrs:{"fetchFunction":_vm.fetchElementTypesFiltered,"parentFilter":_vm.elementTypesFilter,"header":header,"isFilterable":true,"isNumericalFilter":false}})]}},{key:"header.count",fn:function(ref){
var header = ref.header;
return [_c('TableItemFilterMenu',{attrs:{"header":header,"isFilterable":false}})]}},{key:"header.actions",fn:function(ref){
var header = ref.header;
return [_c('TableItemFilterMenu',{attrs:{"header":header,"isFilterable":false}})]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-menu',{attrs:{"bottom":"","left":"","close-on-content-click":!_vm.confirmDeletion},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(_vm._s(_vm.mdiDotsVertical))])],1)]}}],null,true)},[_c('v-list',[_c('v-list-item',{on:{"click":function($event){return _vm.onMenuClick('edit', item)}}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v(_vm._s(_vm.mdiPencil))])],1),_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t('edit'))+" ")])],1),_c('v-list-item',{on:{"click":function($event){return _vm.onMenuClick('delete', item)}}},[_c('v-list-item-icon',[_c('v-icon',{attrs:{"color":_vm.confirmDeletion ? 'error' : null}},[_vm._v(" "+_vm._s(_vm.mdiDelete)+" ")])],1),_c('v-list-item-title',[_c('span',{class:_vm.confirmDeletion ? 'confirm-deletion' : null},[_vm._v(" "+_vm._s(_vm.confirmDeletion ? _vm.$t('confirmDeletion') : _vm.$t('delete'))+" ")])])],1)],1)],1)]}}],null,true)}),_c('ElementTypeAddEditDialog',{attrs:{"elementType":_vm.elementTypeSelected,"editMode":_vm.elementTypeAddEditDialogEditMode,"dialogEnabled":_vm.elementTypeAddEditDialogEnabled},on:{"addEditElementTypeDialogClosed":function($event){_vm.elementTypeAddEditDialogEnabled = false},"exit":function($event){_vm.elementTypeAddEditDialogEnabled = false}}}),_c('v-dialog',{attrs:{"max-width":"400px"},model:{value:(_vm.filterDialogEnabled),callback:function ($$v) {_vm.filterDialogEnabled=$$v},expression:"filterDialogEnabled"}},[_c('v-card',[_c('v-card-title',[_c('h5',[_vm._v("Showing over 500 elements will impact your browser's performance. "),_c('br'),_vm._v(" Are you sure? ")])]),_c('v-card-actions',[_c('v-btn',{on:{"click":function($event){return _vm.rollBackfilter()}}},[_vm._v("Cancel")]),_c('v-spacer'),_c('v-btn',{attrs:{"color":"error"},on:{"click":function($event){return _vm.updateFilterParams(_vm.options)}}},[_vm._v("Yes")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }