























































































































































import Vue from 'vue';

import {  mdiFilterVariant, 
          mdiSortAscending, 
          mdiSortDescending, 
          mdiLessThanOrEqual, 
          mdiGreaterThanOrEqual, 
          mdiEqual, 
          mdiApproximatelyEqual,
          mdiNotEqual } from '@mdi/js';
import { mapActions, mapGetters } from 'vuex';
import { FilterQueryCondition, FilterQuerySelect, FilterQueryConditionExpression, FilterQuery } from '@/store/project/types';
import { FETCH_ELEMENTS_FILTERED, GET_ELEMENTS_FILTER } from '@/store/project/constants';
import { DataTableHeader } from 'vuetify';

export default Vue.extend({
  name: 'TableItemFilterMenu',
  props:{
    header: {
      required: true,
      default: {} as DataTableHeader
    },
    isFilterable: {
      required: true,
      default: false
    },
    parentFilter: {
      required: false,
      type: Object as () => FilterQuery,
      default(){ return {} as FilterQuery }
    },
    fetchFunction: {
      required: false,
      type: Function as () => Function,
      default(){ return {} as Function }
    },
    isNumericalFilter: {
      required: false,
      default: false,
    },
    isMultiChoiceFilter: {
      required: false,
      default: false,
    },
    multipleChoiceOptions: {
      required: false,
      default(){ return [] as string[] }
    },
  },
  data() {
    return {
      mdiFilterVariant,
      mdiSortAscending,
      mdiSortDescending,
      mdiEqual,
      mdiLessThanOrEqual,
      mdiGreaterThanOrEqual,
      mdiApproximatelyEqual,
      mdiNotEqual,
      orderingOptions: ['ASC', 'DESC'],
      ordering: 'ASC',
      textValue: '',
      textOperatorOptions: ['LIKE', 'NOT_LIKE', 'EQUAL'],
      numericalValue: -1,
      numericalOperatorOptions: ['LOWER_OR_EQUAL', 'EQUAL', 'GREATER_OR_EQUAL'],
      filterOperator: '',
      lastAppliedNumericalValue: -1,
      lastAppliedTextValue: '',
      lastAppliedOperator: '',
      lastAppliedSelection: '',
      filterQuerySelect: {
        isActive: false,
        field: this.header.value,
        order: ''
      } as FilterQuerySelect,
      filterQueryCondition: {
        isActive: false,
        field: this.header.value,
        expression: {
          value: this.isNumericalFilter ? -1 : '',
          operator: this.isMultiChoiceFilter? 'EQUAL' : ''
        } as FilterQueryConditionExpression
      } as FilterQueryCondition,
    };
  },
  created() {
    // new instance of select and condition for the filter (shared with other filtering columns)
    if (this.parentFilter.selects) {
      this.parentFilter.selects.push(this.filterQuerySelect);
      this.parentFilter.conditions.push(this.filterQueryCondition);
    }
  },
  methods: {
    applyFilter() {
      this.fetchFunction();
      this.lastAppliedOperator = this.filterOperator;
      this.lastAppliedSelection = this.ordering;
      this.lastAppliedNumericalValue = this.numericalValue;
      this.lastAppliedTextValue = this.textValue;
    },
    resetFilter() {
      this.updateOperator('');
      this.updateOrder('');
      this.textValue = this.lastAppliedTextValue = '';
      this.numericalValue = this.lastAppliedNumericalValue = -1;
      this.lastAppliedOperator = this.lastAppliedSelection = '';
      this.fetchFunction();
    },
    updateText(newTextValue: string) {
      if (newTextValue && newTextValue.length > 0) {
        this.textValue = newTextValue;
        this.filterQueryCondition.expression.value = newTextValue;
      }
      if (this.isMultiChoiceFilter) {
        this.filterQueryCondition.isActive = true;
        this.filterQueryCondition.expression.operator = this.filterOperator = 'EQUAL';
      }
    },
    updateNumber(newNumberValue: number) {
      if (newNumberValue > -1) {
        this.filterQueryCondition.expression.value = this.numericalValue = newNumberValue;
        this.filterQueryCondition.expression.value = newNumberValue;
      }
    },
    updateOperator(newValue: any) {
      if (newValue === this.filterOperator || newValue === '') {
        this.filterQueryCondition.expression.operator = this.filterOperator = '';
        this.filterQueryCondition.isActive = false;
      } 
      else {
        this.filterQueryCondition.expression.operator = this.filterOperator = newValue;
        this.filterQueryCondition.isActive = true;
      }
    },
    updateOrder(newValue: '' | 'ASC' | 'DESC') {
      if (newValue === this.ordering || newValue === '') {
        this.filterQuerySelect.order = this.ordering = '';
        this.filterQuerySelect.isActive = false;
      } 
      else {
        this.filterQuerySelect.order = this.ordering = newValue;
        this.filterQuerySelect.isActive = true;
      }
    }
  }

});

