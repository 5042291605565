




























































import Vue from 'vue';
import {
  CREATE_PROPERTY_TYPE,
  PropertyType,
  PropertyUnit
} from '@/store/project';

export default Vue.extend({
  name: 'PropertyTypeAddEditDialog',
  props:{
    propertyType: {
      required:true,
      type: Object as () => PropertyType,
      default(){ return { isNumeric: false, propertyUnits:[{name:"", symbol:""}] as PropertyUnit[]} as PropertyType }
    },
    dialogEnabled: {
      required: true,
      default: false
    },
    editMode: {
      required: true,
      default: false
    },
  },
  data: () => ({
      // props
      valid: false,
  }),
  methods: {
    cancel() {
      this.$emit('addEditPropertyTypeDialogClosed');
    },
    submit() {
      if (this.editMode) this.modify();
      else this.create();
    },
    async create() {
        await this.$store.dispatch(CREATE_PROPERTY_TYPE, this.propertyType);
        (this.$refs.form as HTMLFormElement).reset();
        this.$emit('addEditPropertyTypeDialogClosed');
    },
    // TO DO - modify?
    async modify() {
    //   setTimeout(async () => {
    //     await this.$store.dispatch(UPDATE_PROPERTY_TYPE, this.propertyType);
    //     this.$emit('addEditPropertyTypeDialogClosed');
    //   });
    },
  },
});
