























import Vue from 'vue';

export default Vue.extend({
  name: 'ElementViewPropertyTable',
  data: () => ({
    physicalPropertiesTableHeaders: [
      {
        text: 'Name',
        value: 'propertyType.name',
        sortable: false,
        width: "40%"
      },
      {
        text: 'Value',
        value: 'value',
        sortable: false,
        width: "30%"
      },
      {
        text: 'Unit',
        value: 'propertyUnit.symbol',
        sortable: false,
        width: "30%"
      },
    ],

  }),
  props: {
    properties: {
        required:true,
        default: []
    },
    height: {
        required: false,
        default: 280
    }
  }
});
