


















































































import Vue from 'vue';
import { mapActions, mapGetters } from 'vuex';
import {
  CREATE_MATERIAL_TYPE,
  UPDATE_MATERIAL_TYPE,
  GET_CURRENT_PROJECT,
  MaterialType,
  Circularity,
  MaterialTypeCreate,
  GET_CIRCULARITY_SELECTED
} from '@/store/project';

import CircularityAddEditCard from '@/components/CircularityAddEditCard.vue';
import { mdiHazardLights } from '@mdi/js';

export default Vue.extend({
  name: 'MaterialTypeAddEditDialog',
  components: { CircularityAddEditCard },
  props:{
    materialType: {
      required:true,
      type: Object as () => MaterialType,
      default(){ return {} as MaterialType }
    },
    dialogEnabled: {
      required: true,
      default: false
    },
    editMode: {
      required: true,
      default: false
    },
  },
  data: () => ({
      // icons 
      mdiHazardLights,
      // props
      valid: false,
      step: 1,
      editModeCircularity: false,
  }),
  computed: {
    ...mapGetters({
      currentProject: GET_CURRENT_PROJECT,
      circularitySelected: GET_CIRCULARITY_SELECTED
    }),
  },

  methods: {
    ...mapActions({
      createMaterialType: CREATE_MATERIAL_TYPE
    }),
    close() {
      this.$emit('addEditMaterialTypeDialogClosed');
      this.step = 1;
    },
    cancel() {
      this.$emit('addEditMaterialTypeDialogClosed');
    },
    submit() {
      if (this.editMode) this.modify();
      else this.create();
    },
    circularityDataExists(){
      console.log("called?");
      if (this.materialType.circularity) 
      {
        console.log("false!", this.materialType.circularity);
        return false;
      }
      else return true;
    },
    async create() {
        let newMaterialType = await this.createMaterialType({
          ...this.materialType,
          projectId: this.currentProject.id,
        } as MaterialTypeCreate);
        if(newMaterialType) {
          this.materialType.uid = newMaterialType.uid;      
          this.step = 2;
        } else this. close();

    },
    async modify() {
      setTimeout(async () => {
        await this.$store.dispatch(UPDATE_MATERIAL_TYPE, this.materialType);
        this.$emit('addEditMaterialTypeDialogClosed');
      });
    },
  },
  watch:{ 
    circularitySelected(newValue, oldValue) {
      if (newValue) this.editModeCircularity = true;
      else this.editModeCircularity = false;
    },
    materialType(newValue, oldValue) {
      if (this.editMode === false) this.step = 1;
    }
  }
});
