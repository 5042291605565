









































import Vue from 'vue';
import { mdiQrcode, mdiRemote, mdiRotate3d} from '@mdi/js';
import { PointOfInterest } from '@/store/project/types';
// TO DO - replace with back-end images
import ddcBanner from "@/assets/banner.jpg";

export default Vue.extend({
  name: 'PointOfInterestViewCard',
  props:{
    poi: {
      required:true,
      type: Object as () => PointOfInterest,
      default(){ return {} as PointOfInterest }
    },
    height: {
        required: false,
        default: 500
    }
  },
  data: () => ({
    // icons
    mdiQrcode,
    mdiRemote,
    mdiRotate3d,
    ddcBanner
  }),
  methods: {
    navigateOnBIM(poi: any) {
        // TO DO
        //this.$emit('onViewpointSelected', poi.Viewpoint.CameraView);
    }
  }
});
