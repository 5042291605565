



































































































import Vue from 'vue';
import { mdiDelete, mdiPlus, mdiSearchWeb, mdiCloseCircle, mdiCheck } from '@mdi/js';
import { mapActions, mapGetters } from 'vuex';
import {
  ClassificationEntry,
  ClassificationSystem,
  FETCH_CLASSIFICATION_SYSTEMS,
  GET_CLASSIFICATION_SYSTEMS,
  FETCH_CLASSIFICATION_ENTRIES,
  GET_CLASSIFICATION_ENTRIES,
  ClassificationEntryQuery
} from '@/store/project';

export default Vue.extend({
  name: 'ElementTypeAddEditClassificationTable',
  props: {
    classificationEntries: {
      required:true,
      type: Array as () => ClassificationEntry[],
      default(){ return []}
    }
  },
  data: () => ({
    // icons
    mdiDelete,
    mdiPlus,
    mdiSearchWeb,
    mdiCloseCircle,
    mdiCheck,
    // eddited data
    search: "",
    selectedSystem: [],
    selectedTable: {} as ClassificationSystem,
    selectedEntry: {} as ClassificationEntry,
    classificationTableHeaders: [
      {
        text: 'Code',
        value: 'code',
        sortable: true,
        width: "25%"
      },
      {
        text: 'Label',
        value: 'label',
        sortable: true,
        width: "50%"
      },
      {
        text:'Actions',
        value:'actions',
        sortable: false,
        width: "15%"
      }
    ]
  }),
  async created() {
    await this.fetchClassificationSystems();
  },
  methods: {
    ...mapActions({
      fetchClassificationSystems: FETCH_CLASSIFICATION_SYSTEMS,
      fetchClassificationEntries: FETCH_CLASSIFICATION_ENTRIES
    }),
    async searchCodes() {
      await this.fetchClassificationEntries({
        size: 20,
        systemId: this.selectedTable.id,
        label: this.search,
        property: "label",
        direction: "ascending"
      } as ClassificationEntryQuery);
    },
    addNewEntry() {
      const newEntry : ClassificationEntry = Object.assign({}, this.selectedEntry);
      // check duplicates first
      if (this.checkDuplicateEntries(newEntry)==true) {
        this.classificationEntries.push(newEntry);
      }
    },
    checkDuplicateEntries(newEntry: ClassificationEntry) {
      let isOk = true;
      this.classificationEntries.forEach(entry => {
        if (newEntry.id === entry.id) {
          isOk = false;
        }
      });
      return isOk;
    },
    deleteEntry(item: ClassificationEntry) {
      const index = this.classificationEntries.indexOf(item, 0);
      if (index > -1) this.classificationEntries.splice(index,1);
    },
    validateClassifications(classificationsEntries: ClassificationEntry[]) {
      this.$emit('validateClassifications', classificationsEntries);
      this.selectedSystem = [];
      this.selectedTable = {} as ClassificationSystem;
      this.selectedEntry = {} as ClassificationEntry;
    },
  },
  computed: {
    ...mapGetters({
      foundEntries: GET_CLASSIFICATION_ENTRIES,
      classifications: GET_CLASSIFICATION_SYSTEMS
    }),

  }
});
