

































































import Vue from 'vue';
import { mdiCogOutline, mdiScatterPlotOutline } from '@mdi/js';

import ScanModelConfigurationCard from '@/components/ScanModelConfigurationCard.vue';
import PointsOfInterestListViewDialog from '@/components/PointsOfInterestListViewDialog.vue';
import { mapActions, mapGetters } from 'vuex';
import {
  FETCH_PROJECT_3D_SCAN_CONFIG,
  GET_CURRENT_PROJECT,
  GET_PROJECT_3D_SCAN_CONFIG,
} from '@/store/project';
import { GET_ACDB, FETCH_MULTIPLE_ACDB } from '@/store/acdb';

export default Vue.extend({
  name: "TheScanModelPanel",
  components: {
    ScanModelConfigurationCard,
    PointsOfInterestListViewDialog
  },
  methods: {
    ...mapActions({
      fetchMultipleAcdb: FETCH_MULTIPLE_ACDB,
      fetchCurrentProject3dScanConfig: FETCH_PROJECT_3D_SCAN_CONFIG,
    }),
    closeUpsert3DScanConfigForm() {
      this.displayConfigureProject3DScan = false;
    },
    togglePoiList(){
      this.poiListDialogEnabled = !this.poiListDialogEnabled;
    }
  },
  async created() {
    await this.fetchMultipleAcdb([
      { accessType: 'read', resourceName: 'project3DScanConfig' },
      { accessType: 'read', resourceName: 'ownProject3DScanConfig' },
      { accessType: 'read', resourceName: 'participatingProject3DScanConfig' },
      { accessType: 'create', resourceName: 'project3DScanConfig' },
      { accessType: 'update', resourceName: 'project3DScanConfig' },
    ]);
    await this.fetchCurrentProject3dScanConfig({projectId: this.currentProject.id});
    this.canUpsertProject3DScanConfig =
      this.getAcdb('create', 'project3DScanConfig').hasAccess ||
      (this.getAcdb('update', 'project3DScanConfig').hasAccess &&
        !!this.currentProject3dScanConfig.id);
  },
  data() {
    return {
      mdiCogOutline,
      mdiScatterPlotOutline,
      displayConfigureProject3DScan: false,
      canUpsertProject3DScanConfig: false,
      poiListDialogEnabled: false,
    };
  },
  computed: {
    ...mapGetters({
      currentProject: GET_CURRENT_PROJECT,
      currentProject3dScanConfig: GET_PROJECT_3D_SCAN_CONFIG,
      getAcdb: GET_ACDB,
    }),
  },
});
