


































































































































































































































































import Vue from 'vue';
import { mapActions, mapGetters  } from 'vuex';
import { mdiChevronDoubleDown, mdiChevronDoubleUp} from '@mdi/js';
import {
  FETCH_ELEMENT_TYPES,
  GET_ELEMENT_TYPES,
  FETCH_MATERIAL_TYPES,
  GET_MATERIAL_TYPES,
  CREATE_ELEMENT,
  GET_CURRENT_PROJECT,
  UPDATE_ELEMENT,
  SET_ELEMENT_TYPE_SELECTED,
  GET_ELEMENT_TYPE_SELECTED,
  Element,
  ReuseDecision,
  SurfaceDamage,
  HazardAssessment,
  HazardAssessmentStatus,
  Material,
  PhysicalProperty,
  ElementType,
  GET_CIRCULARITY_SELECTED
} from '@/store/project';
import { isEmpty } from 'lodash-es';
import i18n from '@/i18n';
import { isNumeric } from '@/utils';

import ElementTypeAddEditDialog from '@/components/ElementTypeAddEditDialog.vue';
import ElementAddEditMaterialTable from '@/components/ElementAddEditMaterialTable.vue';
import ElementAddEditPropertyTable from '@/components/ElementAddEditPropertyTable.vue';
import CircularityAddEditCard from '@/components/CircularityAddEditCard.vue';

export default Vue.extend({
  name: 'ElementAddEditDialog',
  components: { ElementTypeAddEditDialog, ElementAddEditMaterialTable, ElementAddEditPropertyTable, CircularityAddEditCard },
  props:{
    element: {
      required:true,
      type: Object as () => Element,
      default(){ return {} as Element }
    },
    dialogEnabled: {
      required: true,
      default: false
    },
    editMode: {
      required: true,
      default: false
    },
  },
  data() {
    const reuseDecisionList = Object.values(ReuseDecision).map(decision => ({
      value: decision,
      text: `${decision}`,
    }));

    const surfaceDamageList = Object.values(SurfaceDamage).map(damage => ({
      value: damage,
      text: `${damage}`,
    }));

    const hazardAssessmentList = Object.values(HazardAssessment).map(assessment => ({
      value: assessment,
      text: `${assessment}`,
    }));

    const hazardAssessmentStatusList = Object.values(HazardAssessmentStatus).map(status => ({
      value: status,
      text: `${status}`,
    }));

    return {
      //icons 
      mdiChevronDoubleDown,
      mdiChevronDoubleUp,
      materialsEnabledExpanded: false,
      identifiersExpandEnabled: false,
      reuseExpandEnabled: false,
      hazardExpandEnabled: false,
      propsEnabledExpanded: false,
      codesEnabledExpanded: false,
      valid: false,
      search: '',
      reuseDecisionList,
      surfaceDamageList,
      hazardAssessmentList,
      hazardAssessmentStatusList,
      addElementTypeDialogEnabled: false,
      step: 1,
      editModeCircularity: false,
      firstCircularity: {},
    };
  },
  async created() {
    if (this.currentProject && this.currentProject.id) {
      // fetch material types
      await this.fetchMaterialTypes(this.currentProject.id);

      // fetch element types
      await this.fetchElementTypes(this.currentProject.id,);
    }
  },
  computed: {
    ...mapGetters({
      currentProject: GET_CURRENT_PROJECT,
      elementTypes: GET_ELEMENT_TYPES,
      materialTypes: GET_MATERIAL_TYPES,
      selectedElementType: GET_ELEMENT_TYPE_SELECTED,
      circularitySelected: GET_CIRCULARITY_SELECTED
    }),
  },

  methods: {
    ...mapActions({
      fetchElementTypes: FETCH_ELEMENT_TYPES,
      fetchMaterialTypes: FETCH_MATERIAL_TYPES,
      createElement: CREATE_ELEMENT
    }),
    ruleInRange(min = 0, max = 1) {
      return (v: string | number) => {
        let bool = false;
        if (v) {
          if (typeof v === 'number') bool = v >= min && v <= max;
          else if (typeof v === 'string') {
            let float = parseFloat(v);
            if (float !== null && float !== undefined)
              bool = float >= min && float <= max;
          }
        } else if (v === null || v === undefined || v === '') bool = true;
        return bool || 'Must be a number between 0 and 1';
      };
    },
    ruleNumber(v: any) {
      if (isEmpty(v)) return true;
      if (typeof v === 'number') return true;
      if (typeof v === 'string' && isNumeric(v)) return true;
      return i18n.t('numberField');
    },
    ruleGreaterThan(value: number, orEquals = false) {
      return (v: string | number) => {
        if (isEmpty(v)) return true;
        const n = typeof v === 'string' ? parseFloat(v) : v;
        if (orEquals) return n >= value || i18n.t('greaterThan', { value });

        return n > value || i18n.t('greaterThan', { value });
      };
    },
    setMaterials(materials: Material[]) {
      this.element.materials = materials;
      this.materialsEnabledExpanded = false;
    },
    setProperties(properties: PhysicalProperty[]) {
      this.element.properties = properties;
      this.propsEnabledExpanded = false;
    },
    toggleAddElementType() {
      if (this.addElementTypeDialogEnabled === false) {
        // reset selected element and pictures
        this.$store.commit(SET_ELEMENT_TYPE_SELECTED, {});
      }
      this.addElementTypeDialogEnabled = !this.addElementTypeDialogEnabled;
    },
    cancel() {
      this.$emit('addEditElementDialogClosed');
    },
    submit() {
      if (this.editMode) this.modifyElement();
      else this.createNewElement();
    },
    closeDialog() {
      this.$emit('addEditElementDialogClosed');
      this.step = 1;
    },
    async createNewElement() {
      let newElement = await this.createElement({
        ...this.element,
        projectId: this.currentProject.id,
      });
      if (newElement) {
        this.element.uid = newElement.uid;
        this.step = 2;
      }
      else this.closeDialog();
    },
    async modifyElement() {
      // The following setTimeout line is used for a specific case where the combobox is focused and you click directly on edit.
      // The combobox value will not be updated until the combobox lose the focus.
      // Like this, the action is done in the next render (nextTick not working).

      setTimeout(async () => {
        await this.$store.dispatch(UPDATE_ELEMENT, { ...this.element, projectId: this.currentProject.id} );
        this.$emit('addEditElementDialogClosed');
      });
    },
  },
  watch: {
    circularitySelected(newValue, oldValue) {
      if (newValue) this.editModeCircularity = true;
      else this.editModeCircularity = false;
    },
    element: {
      deep: true,
      handler(newValue, oldValue) {
        for (const key in newValue) {
          if (typeof newValue[key] === 'string' && newValue[key] === '')
            (this.element as any)[key] = null;
        }
        if (this.editMode === false) this.step = 1;
      },
    },
  },
});
