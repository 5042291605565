













































































import Vue from 'vue';
import { mapGetters } from 'vuex';
import { mdiFilterRemove, mdiPlus, mdiClose, mdiRestore } from '@mdi/js';
import {
  SET_INVENTORY_FILTER_ACTIVE,
  GET_INVENTORY_FILTER_ACTIVE,
  GET_CURRENT_PROJECT
} from '@/store/project';
const baseItem = {
  name: '',
  value: '',
};
export default Vue.extend({
  name:"ElementTableFilter",
  props: ['apply', 'baseColumns', 'disabled'],
  data: () => ({
      icons: {
        mdiFilterRemove,
        mdiPlus,
        mdiClose,
        mdiRestore,
      },
      open: false,
      items: [{ ...baseItem }],
      valid: false,
      toggleFilters: undefined,
  }),
  computed: {
      ...mapGetters({
      inventoryFilterActive: GET_INVENTORY_FILTER_ACTIVE,
      currentProject: GET_CURRENT_PROJECT,
    }),
    columns() {
      return this.baseColumns.map(
        (column: Record<string, string | boolean>) => {
          column.disabled = !!this.items.find(
            item => item.name === column.value,
          );
          return column;
        },
      );
    },
  },
  methods: {
    addColumn() {
      this.items.push({ ...baseItem });
    },
    removeColumn(index: number): void {
      // search column index in items

      this.items.splice(index, 1);
      // slice items
    },
    resetFiltering() {
      this.items = [{ ...baseItem }];
      this.$store.commit(SET_INVENTORY_FILTER_ACTIVE, false);
      this.$emit('filtering', { apply: true, filters: {} });
    },
    applyFiltering() {
      const formattedItems = this.items.reduce((acc, curr) => {
        acc[curr.name] = curr.value;
        return acc;
      }, {} as any);

      this.$store.commit(SET_INVENTORY_FILTER_ACTIVE, true);
      this.$emit('filtering', { apply: true, filters: formattedItems });
    },

    input($event: any, index: number) {
      this.items[index].value = $event;
    },
  },
  watch: {
    items: {
      deep: true,
      immediate: true,
      handler(newItems) {
        this.valid = newItems.every((item: Record<string, string>) => {
          return !!item.name && !!item.value;
        });
      },
    },
  },
});
