















import Vue from 'vue';
import { ElementTypesAnalysis  } from '@/store/project/types';

export default Vue.extend({
  name: 'ReuseDecisionAnalysisChart',
  props: {
    elementTypesAnalysis: { 
      required: true, 
      type: Object as () => ElementTypesAnalysis,
      default() { return {} as ElementTypesAnalysis  } 
    },
    chartHeight: {
      required: false,
      default: 380
    }
  },
  computed: {
    chartSeries(): any {
        const reuseSeries = [0,0,0,0];
        if (this.elementTypesAnalysis.decisionBackfillingCount) {
          reuseSeries[0] = Number(this.elementTypesAnalysis.decisionBackfillingCount / this.elementTypesAnalysis.count *100);
        }
        if (this.elementTypesAnalysis.decisionRecyclingCount) {
          reuseSeries[1] = Number(this.elementTypesAnalysis.decisionRecyclingCount / this.elementTypesAnalysis.count *100);
        }
        if (this.elementTypesAnalysis.decisionReuseCount) {
          reuseSeries[2] = Number(this.elementTypesAnalysis.decisionReuseCount / this.elementTypesAnalysis.count *100);
        }
        if (this.elementTypesAnalysis.decisionUndefinedCount) {
          reuseSeries[3] = Number(this.elementTypesAnalysis.decisionUndefinedCount / this.elementTypesAnalysis.count *100);
        }

        return reuseSeries;    
      },
    chartOptions(): any {
      return {
        labels: ['Backfilling Decision', 'Recylcing Decision', 'Reuse Decision', 'Undefined'] as string[],
        plotOptions:{
          pie: {
            donut: {
              labels: {
                show: true,
                value: {
                  show: true,
                  formatter(value: string) {
                    return Math.round(parseFloat(value) * 100) / 100 + '%';
                  },
                },
                total: {
                  show: true,
                  color: '#1e1e1e',
                  label: "Elements Reuse Decision",
                  formatter: () => {
                    return this.elementTypesAnalysis.count;
                  },
                },
              },
            },
          },
        },
        tooltip: {
          y: {
            formatter(value: number) {
              return Math.round(value * 100) / 100 + '%';
            },
          },
        },
        legend: {
          width: 300,
          position: 'right',
          horizontalAlign: 'right',
          offsetX: 1,
        },
        chart: {
            id:'reusePotentialChart',
            type:'donut',
            width: '100%',
            toolbar: { show: false }
        },
      }
    },        
  }
});

