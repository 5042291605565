














































































































import Vue from 'vue';
import {
  mdiHomeVariant,
  mdiFloorPlan,
  mdiTrashCan,
  mdiCursorText,
  mdiEarthPlus,
  mdiEyePlusOutline,
} from '@mdi/js';
import { mapGetters } from 'vuex';
import {
  GET_CURRENT_PROJECT,
} from '@/store/project';
import LocationAddEditSiteCoordinatesCard from './LocationAddEditSiteCoordinatesCard.vue';
import LocationAddEditPointOfInterestCard from './LocationAddEditPointOfInterestCard.vue';

export default Vue.extend({
  name: "LocationAddEditSiteCard",
  components: {
    LocationAddEditSiteCoordinatesCard,
    LocationAddEditPointOfInterestCard,
  },
  props: {
    location: {
      required: true,
      type: Object,
    },
    addLocationInTree: {
      required: true,
      type: Function,
    },
    removeLocationInTree: {
      required: true,
      type: Function,
    },
    setLocationInEditMode: {
      required: true,
      type: Function,
    },
    canCreateLocation: {
      required: true,
      type: Boolean,
    },
    canDeleteLocation: {
      required: true,
      type: Boolean,
    },
    canUpdateLocation: {
      required: true,
      type: Boolean,
    },
  },
  data() {
    return {
      mdiHomeVariant,
      mdiFloorPlan,
      mdiTrashCan,
      mdiCursorText,
      mdiEarthPlus,
      mdiEyePlusOutline,
      displaySiteCoordinatesForm: false,
      displayPOIForm: false,
    };
  },
  methods: {
    openCoordinatesForm() {
      (this as any).displaySiteCoordinatesForm = true;
    },
    closeCoordinatesForm() {
      (this as any).displaySiteCoordinatesForm = false;
    },
    openPOIForm() {
      (this as any).displayPOIForm = true;
    },
    closePOIForm() {
      (this as any).displayPOIForm = false;
    },
  }
});
