
























































































import Vue from 'vue';
import { mapGetters } from 'vuex';
// TO DO - add a button to create/edit properties?
import PropertyTypeAddEditDialog from '@/components/PropertyTypeAddEditDialog.vue';

import { mdiWindowClose, mdiContentSave, mdiPencil, mdiDelete, mdiPlus, mdiCheck } from '@mdi/js';
import {
  FETCH_PROPERTY_TYPES,
  GET_PROPERTY_TYPES,
} from '@/store/project';
import {
    PhysicalProperty,
    PropertyType,
    PropertyUnit
} from '@/store/project/types';

export default Vue.extend({
  name: 'ElementAddEditPropertyTable',
  components: { PropertyTypeAddEditDialog },
  data: () => ({
    // icons
    mdiWindowClose,
    mdiContentSave,
    mdiPencil,
    mdiDelete,
    mdiPlus,
    mdiCheck,
    // eddited data
    addPropertyTypeDialogEnabled: false,
    emptyPropertyType:{ isNumeric: false, propertyUnits:[{name:"", symbol:""}] as PropertyUnit[]} as PropertyType,
    selectedPropertyType: {} as PropertyType,
    newEntry:  {
        uid: "-1",
        propertyType: {
            name:""
        },
        propertyUnit: {
            symbol:""
        },
        value: ""
    } as PhysicalProperty,
    editProperty:  {
        uid: "0",
        propertyType: {
            name:""
        },
        propertyUnit: {
            symbol:""
        },
        value: ""
    } as PhysicalProperty,
    emptyProperty:  {
        uid: "0",
        propertyType: {
            name:""
        },
        propertyUnit: {
            symbol:""
        },
        value: ""
    } as PhysicalProperty,
    physicalPropertiesTableHeaders: [
      {
        text: 'Name',
        value: 'propertyType.name',
        sortable: false,
        width: "30%"
      },
      {
        text: 'Unit',
        value: 'propertyUnit.symbol',
        sortable: false,
        width: "15%"
      },
      {
        text: 'Value',
        value: 'value',
        sortable: false,
        width: "40%"
      },
      {
        text:'Actions',
        value:'actions',
        sortable: false,
        width: "15%"
      }
    ]
  }),
  async created() {
    // fetch properties
    await this.$store.dispatch(FETCH_PROPERTY_TYPES, {
    });
  },
  methods: {
    addNewEntry() {
      const newProp : PhysicalProperty = Object.assign({}, this.newEntry);
      newProp.uid += this.properties.length++;
      this.properties.pop();
      this.properties.push(newProp);
      this.editProperty = Object.assign({}, newProp);
    },
    editEntry (item: PhysicalProperty) {
        this.editProperty = Object.assign({}, item);
    },
    resetEntry () {
      this.editProperty = Object.assign({}, this.emptyProperty);
    },
    saveEntry(item: PhysicalProperty) {
      const index = this.properties.indexOf(item, 0);
      if (index > -1) Object.assign(this.properties[index], this.editProperty)
      this.resetEntry();
    },
    deleteEntry(item: PhysicalProperty) {
      const index = this.properties.indexOf(item, 0);
      if (index > -1) this.properties.splice(index,1);
    },
    validateProperties(properties: PhysicalProperty[]) {
      this.$emit('validateProperties', properties);
    },
    toggleAddPropertyType() {
        this.addPropertyTypeDialogEnabled = !this.addPropertyTypeDialogEnabled;
    },
  },
  computed: {
    ...mapGetters({
      propertyTypes: GET_PROPERTY_TYPES,
      //propertyUnits: GET_CLASSIFICATION_SYSTEMS
    }),
  },
  props: {
    properties: {
      required:true,
      type: Array as () => PhysicalProperty[],
      default(){ return []}
    }
  }
});
