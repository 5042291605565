

























import Vue from 'vue';

export default Vue.extend({
  name: 'ElementViewLotTable',
  data: () => ({
    lotTableHeaders: [
      {
        text: 'Lot',
        value: 'Reference',
        sortable: false,
      },
      {
        text: 'Quantity',
        value: 'Quantity',
        sortable: false,
      }
    ],
  }),
  props: {
    lot: {
        required:true,
        default: {}
    },
    height: {
        required: false,
        default: 240
    }
  }
});
