























































import Vue from 'vue';

import LocationAddEditSiteCard from '@/components/LocationAddEditSiteCard.vue';
import LocationAddEditBuildingCard from '@/components/LocationAddEditBuildingCard.vue';
import LocationAddEditStoreyCard from '@/components/LocationAddEditStoreyCard.vue';
import LocationAddEditSpaceCard from '@/components/LocationAddEditSpaceCard.vue';


export default Vue.extend({
  name: "LocationAddEditMenu",
  components: {
    LocationAddEditSiteCard,
    LocationAddEditBuildingCard,
    LocationAddEditStoreyCard,
    LocationAddEditSpaceCard,
  },
  props: {
    location: {
      required: true,
      type: Object,
    },
    addLocationInTree: {
      required: true,
      type: Function,
    },
    removeLocationInTree: {
      required: true,
      type: Function,
    },
    setLocationInEditMode: {
      required: true,
      type: Function,
    },
    canCreateLocation: {
      required: true,
      type: Boolean
    },
    canDeleteLocation: {
      required: true,
      type: Boolean
    },
    canUpdateLocation: {
      required: true,
      type: Boolean
    }
  },
});
