







































import Vue from 'vue';
import i18n from '@/i18n';
import { mapGetters, mapActions } from 'vuex';
import {
  GET_CURRENT_PROJECT,
  UPDATE_LOCATION_FROM_PROJECT,
} from '@/store/project';
export default Vue.extend({
  name: "LocationAddEditPointOfInterestCard",
  props: {
    location: {
      required: true,
      type: Object,
    },
    closePOIForm: {
      required: true,
      type: Function,
    },
  },
  data: () => {
    return {
      isPOIOk: false,
      pointOfInterest: '',
      rules: {
        requiredValidUrl: [
          (v: string) => !!v || i18n.t('mandatoryField'),
          (v: string) =>
            /^(http(s)?:\/\/)[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/gm.test(
              v,
            ) || i18n.t('invalidUrl'),
        ],
      },
    };
  },
  created() {
    (this as any).pointOfInterest = this.location.pointOfInterest;
  },
  methods: {
    ...mapActions({
      updateLocation: UPDATE_LOCATION_FROM_PROJECT,
    }),
    async saveCoordinates() {
      await (this as any).updateLocation({
        id: (this as any).location.id,
        projectId: this.currentProject.id,
        pointOfInterest: (this as any).pointOfInterest,
      });

      (this as any).closePOIForm();
    },
  },
  computed: {
    ...mapGetters({
      currentProject: GET_CURRENT_PROJECT,
    }),
    // isPOIOk: function() {
    //   if ((this as any).pointOfInterest === '') return true;

    //   const wgs84RegExp = /^-?\d+\.*\d*,\s*-?\d+\.*\d*/gi;
    //   return wgs84RegExp.test((this as any).pointOfInterest);
    // },
    havePOIChanged: function() {
      return this.location.pointOfInterest !== (this as any).pointOfInterest;
    },
  },
});
