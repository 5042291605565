




































import Vue from 'vue';
import { mapGetters, mapActions } from 'vuex';
import { GET_CURRENT_PROJECT, UPDATE_LOCATION_FROM_PROJECT } from '@/store/project';
export default Vue.extend({
  name: "LocationAddEditSiteCoordinatesCard",
  props: {
    location: {
      required: true,
      type: Object,
    },
    closeCoordinatesForm: {
      required: true,
      type: Function,
    },
  },
  data: () => {
    return {
      siteCoordinates: '',
    };
  },
  created() {
    (this as any).siteCoordinates = this.location.coordinate;
  },
  methods: {
    ...mapActions({
      updateLocation: UPDATE_LOCATION_FROM_PROJECT,
    }),
    async saveCoordinates() {
      await (this as any).updateLocation({
        id: (this as any).location.id,
        projectId: this.currentProject.id,
        coordinate: (this as any).siteCoordinates,
      });

      (this as any).closeCoordinatesForm();
    },
  },
  computed: {
    ...mapGetters({
      currentProject: GET_CURRENT_PROJECT,
    }),
    areCoordinatesOk: function() {
      if ((this as any).siteCoordinates === '') return true;

      const wgs84RegExp = /^-?\d+\.*\d*,\s*-?\d+\.*\d*/gi;
      return wgs84RegExp.test((this as any).siteCoordinates);
    },
    haveCoordinatesChanged: function() {
      return this.location.coordinate !== (this as any).siteCoordinates;
    },
  },
});
