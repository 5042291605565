




































































































import Vue from 'vue';

import {
  mdiFloorPlan,
  mdiTrashCan,
  mdiCursorText,
  mdiEarthPlus,
  mdiOfficeBuilding,
  mdiEyePlusOutline
} from '@mdi/js';

import LocationAddEditPointOfInterestCard from '@/components/LocationAddEditPointOfInterestCard.vue';

export default Vue.extend({
  name: "LocationAddEditBuildingCard",
  components: {
    LocationAddEditPointOfInterestCard,
  },
  props: {
    location: {
      required: true,
      type: Object,
    },
    addLocationInTree: {
      required: true,
      type: Function,
    },
    removeLocationInTree: {
      required: true,
      type: Function,
    },
    setLocationInEditMode: {
      required: true,
      type: Function,
    },
    canCreateLocation: {
      required: true,
      type: Boolean,
    },
    canDeleteLocation: {
      required: true,
      type: Boolean,
    },
    canUpdateLocation: {
      required: true,
      type: Boolean,
    },
  },
  data() {
    return {
      mdiOfficeBuilding,
      mdiFloorPlan,
      mdiTrashCan,
      mdiCursorText,
      mdiEarthPlus,
      mdiEyePlusOutline,
      displayPOIForm: false,
    };
  },
  methods: {
    openPOIForm() {
      (this as any).displayPOIForm = true;
    },
    closePOIForm() {
      (this as any).displayPOIForm = false;
    },
  },
});
