


























import Vue from 'vue';
import { mdiHazardLights } from '@mdi/js';

export default Vue.extend({
  name: 'ElementViewMaterialTable',
  data: () => ({
    // icons
    mdiHazardLights,
    // table properties
    materialTableHeaders: [
      {
        text: 'Hazard',
        value: 'materialType.isHazard',
        sortable: false,
        width: "10%"
      },
      {
        text: 'Name',
        value: 'materialType.name',
        sortable: false,
        width: "40%"
      },
      {
        text: 'Category',
        value: 'materialType.category',
        sortable: false,
        width: "40%"
      },
      {
        text: 'Mass',
        value: 'mass',
        sortable: false,
        width: "10%"
      },
      {
        text: 'Volume',
        value: 'volume',
        sortable: false,
        width: "10%"
      }
    ]
  }),
  props: {
    materials: {
        required:true,
        default: []
    },
    height: {
        required: false,
        default: 280
    }
  }
});
