




















































































































































































































































































































































































































































import Vue from 'vue';
import { mapActions, mapGetters, mapMutations } from 'vuex';
import CountriesList from 'country-region-data';
import { mdiImageArea, mdiCalendar, mdiDelete } from '@mdi/js';

import i18n from '@/i18n';

import { GET_ACDB, FETCH_MULTIPLE_ACDB } from '@/store/acdb';
import {
  FETCH_FILTERED_USERS,
  GET_CURRENT_USER,
  GET_FILTERED_USERS,
} from '@/store/users';
import {
  ADD_PROJECT,
  FETCH_PROJECTS,
  GET_CURRENT_PROJECT,
  GET_PROJECTS,
  SET_CURRENT_PROJECT,
  UPDATE_PROJECT,
  Project,
  FETCH_PARTICIPANTS_LIST_FROM_CURRENT_PROJECT,
  GET_CURRENT_PROJECT_PARTICIPANTS,
  UPLOAD_PROJECT_PICTURE,
  FETCH_PROJECT_PICTURE,
  GET_PROJECTS_PICTURE,
  DELETE_PROJECT_PICTURE,
} from '@/store/project';

import { isEqual, uniq, find, cloneDeep, isEmpty } from 'lodash';
import { User } from '@/plugins/httpClient/users';

const MAX_LENGTH = 1024;
const BASE_SIZE = 2 ** 10;
const MAX_FILE_SIZE_KB = BASE_SIZE * 1000; // in Kb
const MAX_FILE_SIZE_MB = BASE_SIZE >> 10;

export default Vue.extend({
  name: "TheProjectDetailsPanel",
  async created() {
    await this.fetchMultipleAcdb([
      {
        accessType: 'create',
        resourceName: 'project',
      },
      {
        accessType: 'read',
        resourceName: 'project',
      },
      {
        accessType: 'update',
        resourceName: 'project',
      },
      {
        accessType: 'delete',
        resourceName: 'project',
      },
      {
        accessType: 'create',
        resourceName: 'ownProject',
      },
      {
        accessType: 'read',
        resourceName: 'ownProject',
      },
      {
        accessType: 'update',
        resourceName: 'ownProject',
      },
      {
        accessType: 'delete',
        resourceName: 'ownProject',
      },
      // {
      //   accessType: 'create',
      //   resourceName: 'participatingProject',
      // },
      {
        accessType: 'read',
        resourceName: 'participatingProject',
      },
      {
        accessType: 'update',
        resourceName: 'participatingProject',
      },
    ]);
    await (this as any).fetchUsers({});

    if (!this.isCreationMode) {
      this.modifiedProjectPayload = cloneDeep(this.currentProject);

      // Get and display the project picture if exists
      if (!this.projectsPicture[this.currentProject.id])
        await this.fetchProjectPicture(this.currentProject.id);
    } else if (!(this as any).canChooseProjectAttribute('owner')) {
//      this.modifiedProjectPayload.owner = this.getCurrentUser.id;
      this.modifiedProjectPayload.owner = this.getCurrentUser;
    }
  },
  async mounted() {
    // It's a simple function to check what type of action is done on the page
    // performance.nagivation.types:
    // TYPE_BACK_FORWARD: ?;
    // TYPE_NAVIGATE: ?;
    // TYPE_RESERVED: ?;
    // TYPE_RELOAD: 1;
    if (
      !this.isCreationMode &&
      window.performance &&
      performance.navigation.type === 1
    ) {
      await this.fetchProjectPicture(this.currentProject.id);
    }
  },

  data() {
    return {
      loading: false,
      valid: false,
      projectHasChanged: false,
      rules: {
        required: [(v: any) => !!v || i18n.t('mandatoryField')],
        maxLength: [
          (v: string) =>
            v.length <= MAX_LENGTH ||
            i18n.t('maxLengthField', { value: MAX_LENGTH }),
        ],
        mustBeNumber: [
          (v: any) => {
            return (
              isEmpty(v) ||
              typeof v === 'number' ||
              // !isNaN(parseInt(v)) ||
              /^\d.*$/.test(v) ||
              i18n.t('numberField')
            );
          },
        ],
        maxSize: [
          (value: { size: number }) =>
            !value ||
            value.size < MAX_FILE_SIZE_KB ||
            i18n.t('maxSizeField', { value: MAX_FILE_SIZE_MB }),
        ],
      },
      hasImage: false,
      fileRecord: null as File | null,
      modifiedProjectPayload: {
        id: null,
        shortName: null,
        fullName: null,
        description: '',
        owner: { id: null },
        projectType: null,
        footprint: null,
        deconstructionStart: null,
        contactInfo: null,
        fullAddress: {
          addressLine1: null,
          addressLine2: null,
          city: null,
          stateOrProvince: null,
          zipOrPostalCode: null,
          country: null,
        },
      },
      projectTypes: ['deconstruction', 'renovation'],
      dismantelementDatePicker: false,
      activePicker: null,
      mdiCalendar,
      mdiImageArea,
      mdiDelete,
    };
  },
  methods: {
    ...mapActions({
      fetchUsers: FETCH_FILTERED_USERS,
      createProject: ADD_PROJECT,
      updateProject: UPDATE_PROJECT,
      fetchAllProjects: FETCH_PROJECTS,
      fetchMultipleAcdb: FETCH_MULTIPLE_ACDB,
      fetchProjectParticipants: FETCH_PARTICIPANTS_LIST_FROM_CURRENT_PROJECT,
      uploadProjectPicture: UPLOAD_PROJECT_PICTURE,
      deleteProjectPicture: DELETE_PROJECT_PICTURE,
      fetchProjectPicture: FETCH_PROJECT_PICTURE,
    }),
    ...mapMutations({
      setCurrentProject: SET_CURRENT_PROJECT,
    }),
    canDisplayProjectAttribute(attribute: string): boolean {
      let attributesList: string[] = [];
      attributesList = attributesList.concat(
        this.getAcdb('read', 'project').filteringAttributes,
      );

      if (
        this.currentProject.owner === this.getCurrentUser.id ||
        this.modifiedProjectPayload.owner === this.getCurrentUser.id
      ) {
        attributesList = attributesList.concat(
          this.getAcdb('read', 'ownProject').filteringAttributes,
        );
      }

      if (this.isCurrentUserParticipatingInProject()) {
        attributesList = attributesList.concat(
          this.getAcdb('read', 'participatingProject').filteringAttributes,
        );
      }
      return uniq(attributesList).includes(attribute);
    },
    canChooseProjectAttribute(attribute: string): boolean {
      if (this.isCreationMode) {
        return this.getAcdb('create', 'project').filteringAttributes?.includes(
          attribute,
        );
      } else {
        let attributesList: string[] = [];
        attributesList = attributesList.concat(
          this.getAcdb('update', 'project').filteringAttributes,
        );

        if (this.currentProject.owner === this.getCurrentUser.id) {
          attributesList = attributesList.concat(
            this.getAcdb('update', 'ownProject').filteringAttributes,
          );
        }

        return uniq(attributesList).includes(attribute);
      }
    },
    getProjectPicture() {
      if(this.projectsPicture[this.currentProject.id])
      return this.projectsPicture[this.currentProject.id].src
    },
    deletePicture() {
      this.deleteProjectPicture(this.currentProject.id);
    },
    canDeleteProject(project: Project) {
      const fn = this.getAcdb;
      let deleteProject = fn('update', 'project').hasAccess;
      return deleteProject;
    },
    canDeleteProjectAttribute(attribute: string): boolean {
      let attributesList: string[] = [];
      attributesList = attributesList.concat(
        this.getAcdb('delete', 'project').filteringAttributes,
      );

      if (this.currentProject.owner === this.getCurrentUser.id) {
        attributesList = attributesList.concat(
          this.getAcdb('delete', 'ownProject').filteringAttributes,
        );
      }
      return uniq(attributesList).includes(attribute);
    },
    formatCurrentUserForProjectOwner() {
      const currentUser = this.getCurrentUser;
      if ('email' in currentUser && currentUser.email !== null)
        return `${currentUser.firstName} ${currentUser.name} <${currentUser.email}>`;
      return `${currentUser.firstName} ${currentUser.name} <${currentUser.email}>`;
    },
    formatUserForSelect(user: any) {
      if ('email' in user && user.email !== null)
        return `${user.firstName} ${user.name} <${user.email}>`;
      return `${user.firstName} ${user.name}`;
    },
    getCountriesName() {
      return CountriesList.map(country => country.countryName);
    },
    async validate() {
      try {
        if (this.isCreationMode)
          await (this as any).createProject(this.modifiedProjectPayload);
        else {
          await (this as any).updateProject(this.modifiedProjectPayload);
        }
        await (this as any).fetchAllProjects();
        this.setCurrentProject(
          this.allProjects.find(
            (project: Project) => project.id === this.currentProject.id,
          ),
        );
      } catch (e) {
        // ignore fetch all projects when project shortname already exists!
      } finally {
        this.projectHasChanged = false;
      }
    },
    replaceEmptyString(someObj: any) {
      const res = JSON.parse(
        JSON.stringify(someObj, (_key: string, value: any) =>
          value === '' || value === undefined ? null : value,
        ),
      );

      return res;
    },
    isCurrentUserParticipatingInProject() {
      return !!find(
        this.currentProjectParticipants,
        (participant: any) => participant.userId === this.getCurrentUser.id,
      );
    },
    onChangeImage(file: File) {
      this.hasImage = !!file;
      if (this.hasImage) {
        this.fileRecord = file;
      } else {
        this.fileRecord = null;
      }
    },
    async onUpload() {
      this.loading = true;
      /*const ok = */ await (this as any).uploadProjectPicture({
        projectId: this.currentProject.id,
        file: this.fileRecord,
        metadata: {
          title: 'cover',
          description: undefined,
          locationId: undefined,
          documentAuthor: undefined,
          documentDate: undefined,
        },
      });
      this.loading = false;
    },

    async onDelete() {
      this.loading = true;
      await this.deleteProjectPicture(this.currentProject.id);
      this.loading = false;
    },
  },
  computed: {
    ...mapGetters({
      getAcdb: GET_ACDB,
      getCurrentUser: GET_CURRENT_USER,
      getAllUsers: GET_FILTERED_USERS,
      currentProject: GET_CURRENT_PROJECT,
      allProjects: GET_PROJECTS,
      currentProjectParticipants: GET_CURRENT_PROJECT_PARTICIPANTS,
      projectsPicture: GET_PROJECTS_PICTURE,
    }),
    isCreationMode() {
      if (
        this.currentProject === null ||
        this.currentProject?.id === null ||
        this.currentProject?.id === undefined
      )
        return true;
      else return false;
    },
    exceedMaxSize() {
      if (this.fileRecord && this.fileRecord.size >= MAX_FILE_SIZE_KB)
        return true;
      return false;
    },
    allUsers(): User[] {
      const users: User[] = [...this.getAllUsers];
      users.sort((alice: User, bob: User) => {
        return (
          alice.firstName.localeCompare(bob.firstName) ||
          alice.name.localeCompare(bob.name)
        );
      });
      return users;
    },
  },
  watch: {
    dismantelementDatePicker(val) {
      val && setTimeout(() => ((this.activePicker as any) = 'YEAR'));
    },
    modifiedProjectPayload: {
      deep: true,
      handler: function(newVal, oldVal) {
        if (this.isCreationMode) this.projectHasChanged = true;

        this.projectHasChanged =
          !isEqual(
            this.replaceEmptyString(this.currentProject.fullAddress),
            this.replaceEmptyString(newVal.fullAddress),
          ) ||
          !isEqual(
            this.replaceEmptyString(this.currentProject),
            this.replaceEmptyString(newVal),
          );
        (this.$refs.manageProjectForm as any).validate();
      },
    },
    currentProject: {
      deep: true,
      handler: function(val, oldVal) {
        this.modifiedProjectPayload = cloneDeep(val);
      },
    },
  },
});
