





































































































import Vue from 'vue';
import { mapGetters, mapActions } from 'vuex';
import { GET_CURRENT_PROJECT, DELETE_PROJECT_FILE } from '@/store/project';
import { clone, drop, find } from 'lodash-es';
import { GET_FILTERED_USERS } from '@/store/users';
import { User } from '@/plugins/httpClient/users';
export default Vue.extend({
  name: "DocumentDeleteCard",
  props: {
    locations: {
      type: Array,
      required: true,
    },
    currentDocument: {
      type: Object,
      required: true,
    },
    hideDeleteDocumentDialog: {
        type: Function,
        required: true
    }
  },
  data: () => {
    return {};
  },
  methods: {
    ...mapActions({
      deleteDocument: DELETE_PROJECT_FILE
    }),
    formatBytes(a: number, b = 2, k = 1000) {
      let d = Math.floor(Math.log(a) / Math.log(k));
      return 0 == a
        ? '0 Bytes'
        : parseFloat((a / Math.pow(k, d)).toFixed(Math.max(0, b))) +
            ' ' +
            ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'][d];
    },
    async confirmDocumentDeletion() {
      await (this as any).deleteDocument({projectId: this.currentProject.id, documentId: this.currentDocument.id});
      this.hideDeleteDocumentDialog();
    },
  },
  computed: {
    ...mapGetters({
      currentProject: GET_CURRENT_PROJECT,
      allUsers: GET_FILTERED_USERS,
    }),
    site() {
      if (this.locations.length > 0) return (this.locations[0] as any).text;
      return '';
    },
    formatedLocations() {
      if (this.locations.length > 1) {
        let copiedSiteAndLocations = clone(this.locations);
        let formatedString = '';
        copiedSiteAndLocations = drop(copiedSiteAndLocations);

        copiedSiteAndLocations.forEach((item: any) => {
          formatedString = formatedString + item.text + ' / ';
        });

        if (copiedSiteAndLocations.length > 0)
          return formatedString.substring(0, formatedString.length - 2);

        return formatedString;
      }
      return '';
    },
  },
});
