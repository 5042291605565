



























































import Vue from 'vue';

import { mapGetters } from 'vuex';
import PointOfInterestViewCard from '@/components/PointOfInterestViewCard.vue';
import { mdiSearchWeb, mdiCloseCircle } from '@mdi/js';
import { PointOfInterest } from '@/store/project/types';

// temporary image imports
// TO DO - replace with back-end images
 import ddcBanner from "@/assets/banner.jpg";
import { GET_CURRENT_PROJECT, FETCH_POINTS_OF_INTEREST, GET_POINTS_OF_INTEREST } from '@/store/project';

export default Vue.extend({
  name: 'PointsOfInterestListViewDialog',
  components: { PointOfInterestViewCard },
  props: {
    dialogEnabled: {
      required: true,
      default: false,
    }
  },
  data: () => ({
    // the model for searching data across POIs
    searchValue: '',
    searchProperty: 'name',
    searchQuery: {
        size: 10,
        name: '',
        description:'',
        //locationId:'',
        weblink:'',
        property: "name",
        direction: "ascending"
    } as any,
    // icons
    mdiSearchWeb,
    mdiCloseCircle,
    // sample data
    searchQueryProperties: [
        'name', 'description', 'weblink'
    ],
    searchQueryDirections: [
        'ascending', 'descending'
    ],
  }),
  async created() {
    // fetch classifications
    let query = {
        size: 5,
        property: "name",
        direction: "ascending",
        name: '',
        description: '',
        //locationId: '',
        weblink: ''
    };
    await this.$store.dispatch(FETCH_POINTS_OF_INTEREST, { projectId: this.currentProject.id, query });
  },
  computed: {
    ...mapGetters({
      currentProject: GET_CURRENT_PROJECT,
      pointsOfInterest: GET_POINTS_OF_INTEREST
    })
  },
  methods: {
    async searchPois(){
        let payload = { query: this.searchQuery, projectId: this.currentProject.id }
        await this.$store.dispatch(FETCH_POINTS_OF_INTEREST, payload);
    },
  },
  watch: {
    searchValue(newValue, oldValue) {
        if (newValue === null) this.searchQuery[this.searchProperty] = '';
        else this.searchQuery[this.searchProperty] = newValue;
    },
    searchProperty(newValue, oldValue) {
        this.searchQuery[newValue] = this.searchValue;
        this.searchQuery[oldValue] = '';
    }
  }
});
