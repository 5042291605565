





















































































































import Vue from 'vue';
import { mapActions, mapGetters, mapMutations } from 'vuex';
import { mdiDotsVertical, mdiPencil, mdiDelete, mdiPlus } from '@mdi/js';

import ElementTypeAddEditDialog from '@/components/ElementTypeAddEditDialog.vue';
import ElementTableFilter from '@/components/ElementTableFilter.vue';
import TableItemFilterMenu from '@/components/TableItemFilterMenu.vue';

import {
  GET_CURRENT_PROJECT,
  DELETE_ELEMENT_TYPE,
  FETCH_ELEMENT_TYPE_SELECTED,
  FETCH_ELEMENT_TYPE_IMAGE,
  GET_ELEMENT_TYPE_SELECTED,
  SET_ELEMENT_TYPE_SELECTED,
  ProjectFile,
  SET_CIRCULARITY_SELECTED,
  SET_ELEMENT_TYPES_FILTER,
  GET_ELEMENT_TYPES_FILTER,
  FETCH_ELEMENT_TYPES_FILTERED,
  GET_ELEMENT_TYPES_FILTERED
} from '@/store/project';

import { ElementType, ElementTypeAnalysis, ElementTypeFileDownloadDelete, FilterQuery, FilterQueryCondition, FilterQuerySelect } from '@/store/project/types';

export default Vue.extend({

  name: 'ElementTypeTable',
  components: { ElementTypeAddEditDialog, ElementTableFilter, TableItemFilterMenu  },
  data() {
    const headers = [
      { text: 'Name', value: 'name', sortable: false },
      { text: 'Description', value: 'description', sortable: false, width: "20%" },
      { text: 'Category', value: 'category', sortable: false, width: "5%" },
      { text: 'IFC Type', value: 'ifcType', sortable: false, width: "5%" },
      { text: 'Designer', value: 'designer', sortable: false, width: "5%" },
      { text: 'Trademark', value: 'trademark', sortable: false, width: "5%" },
      { text: 'Historical Value', value: 'historicalValue', sortable: false, width: "5%" },
      { text: 'Actions', value: 'actions', align: 'center', sortable: false, width: "5%" },
    ];
    return {
      mdiDotsVertical,
      mdiPencil,
      mdiDelete,
      mdiPlus,
      elementTypeAddEditDialogEnabled: false,
      elementTypeAddEditDialogEditMode: false,
      headers,
      filterDialogEnabled: false,
      confirmDeletion: false,
      item: {} as ElementTypeAnalysis,
      isLoading: false,
      options: {
        groupBy: [],
        groupDesc: [],
        itemsPerPage: 10,
        multiSort: false,
        mustSort: false,
        page: 1,
        sortBy: [],
      },
      oldOptions: {
        groupBy: [],
        groupDesc: [],
        itemsPerPage: 10,
        multiSort: false,
        mustSort: false,
        page: 1,
        sortBy: [],
      },
    };
  },
  computed: {
    ...mapGetters({
      currentProject: GET_CURRENT_PROJECT,
      elementTypeSelected: GET_ELEMENT_TYPE_SELECTED,
      elementTypesFilter: GET_ELEMENT_TYPES_FILTER,
      elementTypesFiltered: GET_ELEMENT_TYPES_FILTERED
    }),
  },
  methods: {
    ...mapActions({
      deleteElementType: DELETE_ELEMENT_TYPE,
      fetchElementTypeImage: FETCH_ELEMENT_TYPE_IMAGE,
      fetchElementTypeSelected: FETCH_ELEMENT_TYPE_SELECTED,
      fetchElementTypesFiltered: FETCH_ELEMENT_TYPES_FILTERED
    }),
    ...mapMutations({
      setElementTypeSelected: SET_ELEMENT_TYPE_SELECTED,
      setCircularitySelected: SET_CIRCULARITY_SELECTED,
      setElementTypesFilter: SET_ELEMENT_TYPES_FILTER
    }),
    createElementType(){
      this.setElementTypeSelected({} as ElementType);
      this.setCircularitySelected(undefined);
      this.elementTypeAddEditDialogEnabled = true;
      this.elementTypeAddEditDialogEditMode = false;
    },
    editElementType(elementType: ElementType) {
      if(elementType.projectId === undefined) elementType.projectId = this.currentProject.id;
      this.fetchElementTypeSelected(elementType);
      this.elementTypeAddEditDialogEnabled = true;
      this.elementTypeAddEditDialogEditMode = true;
    },
    onMenuClick(action: string, item: ElementTypeAnalysis) {
      if (action === 'edit') {
        this.editElementType(item);
        this.confirmDeletion = false;
      } else if (action === 'delete' && !this.confirmDeletion) {
        this.confirmDeletion = true;
      } else if (action === 'delete' && this.confirmDeletion) {
        item.projectId = this.currentProject.id;
        this.deleteElementType(item);
        this.confirmDeletion = false;
      }
    },
    rollBackfilter () {
      this.options.itemsPerPage = this.oldOptions.itemsPerPage;
      this.updateFilterParams(this.oldOptions);
    },
    async updateFilterParams(newValue: any) {
      if (this.filterDialogEnabled) this.filterDialogEnabled = false;
      this.isLoading = true;
      if (newValue.itemsPerPage === -1) newValue.itemsPerPage = this.elementTypesFiltered.count;
      try {
        //let sizeLimit = newValue.itemsPerPage === -1 ? 30 : newValue.itemsPerPage;
        this.elementTypesFilter.size = newValue.itemsPerPage;
        this.elementTypesFilter.offset = (newValue.page - 1) * newValue.itemsPerPage;
        await this.fetchElementTypesFiltered();
      } catch(error) {
      } finally {
        this.isLoading = false;
      }
    },
  },
  created() {
    this.setElementTypesFilter({
      size: 10,
      offset: 0,
      selects: [] as FilterQuerySelect[],
      conditions: [] as FilterQueryCondition[]
    } as FilterQuery)
  },
  watch: {
    elementTypeSelected (newElementType, oldElementType) {
        if (newElementType.files === undefined) return;
        newElementType.files.forEach((document: ProjectFile) => {
        if (document.fileType === "image/png" || document.fileType === "image/jpeg" || document.fileType === "image/webp")
        {
          this.fetchElementTypeImage({ 
            projectId:this.currentProject.id, 
            elementTypeUid: newElementType.uid, 
            document: document
          } as ElementTypeFileDownloadDelete);
        }
      });
    },
    options: {
      deep: true,
      immediate: true,
      handler(newValue, oldValue) {
        if (newValue.itemsPerPage === -1 && this.elementTypesFiltered.count > 500) {
          this.filterDialogEnabled = true;
          this.oldOptions = { ...oldValue };
        } else {
          this.updateFilterParams(newValue);
        }
      },
    },
  },
});
