var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',[_c('v-data-table',{attrs:{"dense":"","headers":_vm.materialTableHeaders,"items":_vm.materials,"hide-default-footer":""},scopedSlots:_vm._u([{key:"item.materialType.isHazard",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{attrs:{"color":!item.isHazard ? 'black': 'red'}},[_vm._v(_vm._s(_vm.mdiHazardLights))])]}},{key:"item.materialType.name",fn:function(ref){
var item = ref.item;
return [(_vm.editMaterial.uid === item.uid)?_c('v-autocomplete',{attrs:{"dense":"","items":_vm.materialTypes,"item-text":function (materialType) { return materialType.name; },"item-value":function (materialType) { return materialType; },"hide-details":""},model:{value:(_vm.editMaterial.materialType),callback:function ($$v) {_vm.$set(_vm.editMaterial, "materialType", $$v)},expression:"editMaterial.materialType"}}):_c('span',[_vm._v(_vm._s(item.materialType.name))])]}},{key:"item.mass",fn:function(ref){
var item = ref.item;
return [(_vm.editMaterial.uid === item.uid)?_c('v-text-field',{attrs:{"type":"number","hide-details":"","dense":"","single-line":"","autofocus":""},model:{value:(_vm.editMaterial.mass),callback:function ($$v) {_vm.$set(_vm.editMaterial, "mass", $$v)},expression:"editMaterial.mass"}}):_c('span',[_vm._v(_vm._s(item.mass))])]}},{key:"item.volume",fn:function(ref){
var item = ref.item;
return [(_vm.editMaterial.uid === item.uid)?_c('v-text-field',{attrs:{"type":"number","hide-details":"","dense":"","single-line":"","autofocus":""},model:{value:(_vm.editMaterial.volume),callback:function ($$v) {_vm.$set(_vm.editMaterial, "volume", $$v)},expression:"editMaterial.volume"}}):_c('span',[_vm._v(_vm._s(item.volume))])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [(_vm.editMaterial.uid === item.uid)?_c('div',[_c('v-icon',{staticClass:"mr-3",attrs:{"color":"red"},on:{"click":function($event){return _vm.resetEntry()}}},[_vm._v(" "+_vm._s(_vm.mdiWindowClose)+" ")]),_c('v-icon',{attrs:{"color":"green"},on:{"click":function($event){return _vm.saveEntry(item)}}},[_vm._v(" "+_vm._s(_vm.mdiContentSave)+" ")])],1):_c('div',[_c('v-icon',{staticClass:"mr-3",attrs:{"color":"green"},on:{"click":function($event){return _vm.editEntry(item)}}},[_vm._v(" "+_vm._s(_vm.mdiPencil)+" ")]),_c('v-icon',{attrs:{"color":"red"},on:{"click":function($event){return _vm.deleteEntry(item)}}},[_vm._v(" "+_vm._s(_vm.mdiDelete)+" ")])],1)]}}],null,true)})],1),_c('br'),_c('v-container',[_c('v-row',[_c('v-autocomplete',{attrs:{"dense":"","filled":"","solo-inverted":"","outlined":"","items":_vm.materialTypes,"label":"material types","item-text":function (v) { return v.name; }}}),_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.toggleAddMaterialType}},[_vm._v("Create")]),_c('v-spacer'),_c('v-spacer'),_c('v-btn',{attrs:{"color":"secondary"},on:{"click":function($event){return _vm.addNewEntry()}}},[_c('v-icon',[_vm._v(_vm._s(_vm.mdiPlus))])],1),_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){return _vm.validateMaterials(_vm.materials)}}},[_c('v-icon',[_vm._v(_vm._s(_vm.mdiCheck))])],1)],1)],1),_c('MaterialTypeAddEditDialog',{attrs:{"materialType":_vm.emptyMaterialType,"editMode":false,"dialogEnabled":_vm.addMaterialTypeDialogEnabled},on:{"addEditMaterialTypeDialogClosed":_vm.toggleAddMaterialType,"exit":_vm.toggleAddMaterialType}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }