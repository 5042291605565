import { render, staticRenderFns } from "./ElementViewImageCarousel.vue?vue&type=template&id=6d3eb5f0&"
import script from "./ElementViewImageCarousel.vue?vue&type=script&lang=ts&"
export * from "./ElementViewImageCarousel.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VCarousel } from 'vuetify/lib/components/VCarousel';
import { VCarouselItem } from 'vuetify/lib/components/VCarousel';
import { VChip } from 'vuetify/lib/components/VChip';
import { VImg } from 'vuetify/lib/components/VImg';
installComponents(component, {VCard,VCarousel,VCarouselItem,VChip,VImg})
