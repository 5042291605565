var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-card',{attrs:{"outlined":""}},[_c('v-row',{staticClass:"mb-2 ml-2 mr-2 mt-6"},[_c('v-col',{attrs:{"cols":"6"}},[_c('v-treeview',{attrs:{"items":_vm.formatedProjectLocations,"item-children":"subdivisions","item-key":"id","open-all":"","hoverable":"","rounded":"","transition":"","open":_vm.openItems},on:{"update:open":_vm.setOpenItems},scopedSlots:_vm._u([{key:"prepend",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{attrs:{"color":"primary"}},[_vm._v(" "+_vm._s(_vm.icons[item.type])+" ")])]}},{key:"label",fn:function(ref){
var item = ref.item;
return [(!!item.name && item.name !== '' && !_vm.isEditingMode(item))?_c('div',[_vm._v(" "+_vm._s(item.name)+" ")]):_c('v-text-field',{ref:"locationToSave",attrs:{"autofocus":"","error-messages":_vm.textfieldError},on:{"blur":function($event){return _vm.onBlurItemName($event, item)}},model:{value:(_vm.locationToEdit.name),callback:function ($$v) {_vm.$set(_vm.locationToEdit, "name", $$v)},expression:"locationToEdit.name"}})]}},{key:"append",fn:function(ref){
var item = ref.item;
return [(!!item.pointOfInterest && item.pointOfInterest !== '')?_c('v-icon',{staticStyle:{"margin":"0 4px !important"},attrs:{"color":"primary"},on:{"click":function($event){return _vm.displayPointOfInterset(item)}}},[_vm._v(" "+_vm._s(_vm.mdiEyeOutline)+" ")]):_vm._e(),(!!item.coordinate && item.coordinate !== '')?_c('v-icon',{staticStyle:{"margin":"0 4px !important"},attrs:{"color":"primary"},on:{"click":function($event){return _vm.displayOpenStreetMapWithCoordinates(item)}}},[_vm._v(" "+_vm._s(_vm.mdiEarth)+" ")]):_vm._e(),(
              (!item.subdivisions || item.subdivisions.length === 0) &&
                !!item.name &&
                item.name !== '' &&
                item.type !== 'project' &&
                !_vm.doesLocationHaveDocuments(item.id) &&
                _vm.canDeleteLocation()
            )?_c('v-icon',{staticStyle:{"margin":"0 4px !important"},attrs:{"color":"primary"},on:{"click":function($event){return _vm.removeLocation(item)}}},[_vm._v(" "+_vm._s(_vm.mdiTrashCan)+" ")]):_vm._e(),_c('v-menu',{scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [(item.type !== 'project')?_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","color":"primary"},on:{"click":function($event){return _vm.showContextMenu($event, item)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" "+_vm._s(_vm.mdiDotsVertical)+" ")])],1):_vm._e()]}}],null,true)},[_c('LocationAddEditMenu',{attrs:{"location":_vm.selectedLocation,"addLocationInTree":_vm.addLocationInTree,"removeLocationInTree":_vm.removeLocationInTree,"setLocationInEditMode":_vm.setLocationInEditMode,"canCreateLocation":_vm.canCreateLocation(),"canDeleteLocation":_vm.canDeleteLocation(),"canUpdateLocation":_vm.canUpdateLocation()}})],1)]}}])})],1),_c('v-col',[_c('v-row',{staticClass:"text-h5 mx-2",attrs:{"align":"center"}},[_c('v-badge',{attrs:{"color":_vm.openStreetMap.name ? 'success' : 'error',"dot":""}},[(_vm.openStreetMap.name)?_c('p',[_vm._v(_vm._s(_vm.openStreetMap.name))]):_c('p',[_vm._v("No site selected")])]),_c('v-spacer'),(_vm.canCreateLocation())?_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){return _vm.addSite()}}},[_c('v-icon',[_vm._v(_vm._s(_vm.mdiPlus))])],1):_vm._e()],1),_c('v-row',{directives:[{name:"show",rawName:"v-show",value:(_vm.openStreetMap.src),expression:"openStreetMap.src"}]},[_c('v-col',[_c('v-responsive',{ref:"openStreetMap",attrs:{"aspect-ratio":16 / 9}},[_c('iframe',{attrs:{"width":"100%","height":"100%","frameborder":"0","scrolling":"no","marginheight":"0","marginwidth":"0","src":_vm.openStreetMap.src}})]),_c('small',[_c('a',{attrs:{"href":_vm.openStreetMap.extendSrc,"target":"_blank"}},[_vm._v("View Larger Map")])])],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }