

















































































import Vue from 'vue';
import { mapGetters } from 'vuex';

import { mdiWindowClose, mdiContentSave, mdiPencil, mdiDelete, mdiPlus, mdiCheck, mdiUpload, mdiDownload } from '@mdi/js';
import {
  UPLOAD_CIRCULARITY_DOCUMENT,
  UPDATE_CIRCULARITY_DOCUMENT,
  DELETE_CIRCULARITY_DOCUMENT,
  DOWNLOAD_CIRCULARITY_DOCUMENT,
  GET_CURRENT_PROJECT
} from '@/store/project';
import {
  CircularityFileUploadUpdate,
    ProjectFile,
} from '@/store/project/types';
import { time } from 'console';

export default Vue.extend({
  name: 'CircularityAddEditDocumentTable',
  data: () => ({
    // icons
    mdiWindowClose,
    mdiContentSave,
    mdiPencil,
    mdiDelete,
    mdiPlus,
    mdiCheck,
    mdiUpload,
    mdiDownload,
    // eddited data
    documentToUpload: null as File | null,
    selectedDocument: {} as ProjectFile,
    confirmDeleteUid: "",
    newDocument:  {
        uid: "-1",
        title: "",
        documentDate:"",
        fileType: ""
    } as ProjectFile,
    editDocument:  {
        uid: "-1",
        title: "",
        documentDate:"",
        fileType: ""
    } as ProjectFile,
    emptyDocument:  {
        uid: "0",
        title: "",
        documentDate:"",
        fileType: ""
    } as ProjectFile,
    documentsTableHeaders: [
      {
        text: 'Title',
        value: 'title',
        sortable: false,
        width: "60%"
      },
      {
        text: 'Date',
        value: 'documentDate',
        sortable: false,
        width: "20%"
      },
      {
        text:'Actions',
        value:'actions',
        sortable: false,
        width: "20%"
      }
    ]
  }),
  async created() {
    // fetch documents
    // emit to parent to fetch respective documents?
  },
  methods: {
    addDocument() {
      const newDoc : ProjectFile = Object.assign({}, this.newDocument);
      newDoc.uid += this.documents.length++;
      this.documents.pop();
      this.documents.push(newDoc);
      this.editDocument = Object.assign({}, newDoc);
    },
    editEntry (item: ProjectFile) {
        this.editDocument = Object.assign({}, item);
    },
    resetEntry () {
      this.editDocument = Object.assign({}, this.emptyDocument);
    },
    saveEntry(item: ProjectFile) {
      let hasChanged = false;
      if (item.title != this.editDocument.title) hasChanged=true;
      if (hasChanged) this.updateDocument(item)// dipatch document info patch
      this.resetEntry();
    },
    deleteEntry(item: ProjectFile) {
      if (this.confirmDeleteUid == item.uid) this.deleteDocument(item);
      this.confirmDeleteUid = item.uid;
    },
    onChangeDocument(file: File) {
      if (!!file) {
        this.documentToUpload = file;
      }
    },
    formatDate(date: Date) {
      if (date) return new Date(date).toLocaleDateString();
    },
    resetDeleteAction()
    {
      this.confirmDeleteUid = "";
    },
    async download(item: ProjectFile) {
      const response = await this.$store.dispatch(DOWNLOAD_CIRCULARITY_DOCUMENT, {
        projectId: this.currentProject.id,
        circularityUid: this.parentUid,
        fileUid: item.uid,
        fileName: item.title
      });
    },
    async uploadDocument() {
      const newDocument = await this.$store.dispatch(UPLOAD_CIRCULARITY_DOCUMENT, {
          circularityUid: this.parentUid,
          file: this.documentToUpload,
          title: this.documentToUpload?.name,
          documentDate: new Date()
        } as CircularityFileUploadUpdate,
      );
      if (newDocument) this.documents.push(newDocument);
    },
    async updateDocument(documentToUpdate: ProjectFile) {
      const response = await this.$store.dispatch(UPDATE_CIRCULARITY_DOCUMENT, {
        projectId: this.currentProject.id,
        circularityUid: this.parentUid,
        file: documentToUpdate,
      });
    },
    async deleteDocument(documentToDelete: ProjectFile) {
      const response = await this.$store.dispatch(DELETE_CIRCULARITY_DOCUMENT, {
        projectId: this.currentProject.id,
        circularityUid: this.parentUid,
        fileUid: documentToDelete.uid,
      });
      if(response) {
        const index = this.documents.indexOf(documentToDelete, 0);
        if (index > -1) this.documents.splice(index,1);
      }
    },
  },
  computed: {
    ...mapGetters({
        currentProject: GET_CURRENT_PROJECT
    }),
  },
  props: {
    documents: {
      required:true,
      type: Array as () => ProjectFile[],
      default(){ return []}
    },
    editMode: {
      required:true,
      default:false
    },
    parentUid: {
        required: false,
    }
  }
});
